<template>
  <Head>
    <title>{{ $t("message.seo.home.title") }}</title>
    <meta name="description" :content="$t('message.seo.home.description')" />
    <meta name="keywords" :content="$t('message.seo.home.keywords')" />
  </Head>
  <div>
    <!-- Title Graphic/video -->
    <div class="grid grid-cols-8 gap-0">
      <div
        class="hidden col-span-1 md:flex flex-wrap justify-center content-center h-full pl-"
      >
        <p
          class="flex font-source text-sm font-semibold uppercase whitespace-nowrap word-spacing-wide transform -rotate-90 tracking-widest"
        >
          {{ $t("message.claim") }}
        </p>
      </div>
      <div class="relative md:col-span-7 col-span-8">
        <video autoplay loop muted class="absolute object-cover w-full h-full">
          <source
            :src="require('@/assets/img/wpage_snippet.mp4')"
            type="video/mp4"
          />
        </video>
        <img
          class="w-full"
          src="../assets/img/quickloadTitle.jpg"
          :alt="$t('message.alt.title')"
        />
        <div
          class="absolute top-0 left-0 w-full h-full bg-black opacity-30"
        ></div>
        <div
          class="absolute bottom-0 right-0 md:grid md:grid-cols-7 pr-4 md:pr-0"
        >
          <h1
            class="font-sen font-bold text-l md:text-3xl text-4xl xl:text-5xl text-white col-span-6 text-right pb-16"
            v-html="$t('message.claim2')"
          ></h1>
        </div>
      </div>
    </div>
    <!-- More -->
    <div class="grid grid-cols-5 md:grid-cols-4 gap-0">
      <div
        class="cursor-pointer w-full relative md:static md:flex bottom-1/2 md:bottom-0 flex-row align-middle justify-left col-span-2 md:col-span-1 uppercase font-source text-white text-sm bg-black text-center pl-2 md:pl-4 pt-4 pb-4"
        v-on:click="
          () => {
            scrollTo('pageBody');
          }
        "
      >
        <div class="w-full h-full flex">
          <img
            class="transform rotate-90 h-10 w-10 bg-white arrow-icon"
            alt=">"
          />
          <span class="pl-4 mt-auto mb-auto">{{ $t("message.more1") }}</span>
        </div>
      </div>
    </div>
    <!-- Body -->
    <div ref="pageBody" class="grid grid-cols-8 gap-0 mb-10">
      <div class="col-start-2 col-end-8">
        <!-- Section 1 -->
        <h1
          class="font-sen font-bold text-lg md:text-2xl lg:text-3xl xl:text-4xl pt-16 xl:pt-20 pb-16 xl:pb-20"
        >
          {{ $t("message.home.h1") }}
        </h1>
        <div
          class="w-full md:grid md:grid-cols-2 gap-2 pb-16 xl:pb-20 font-source md:text-sm lg:text-base xl:text-lg lg:space-y-4"
        >
          <p
            class="md:col-start-1 md:col-end-2 lg:col-start-2 lg:col-end-3"
            v-html="$t('message.home.intro1')"
          ></p>
          <div class="relative flex-col pt-4 pb-4 md:hidden lg:grid lg:row-start-1 lg:row-end-3 flex items-center justify-center">
            <img
              class="w-full pl-10 pr-10"
              src="../assets/img/webar-example.png"
              :alt="$t('message.alt.arMethod')"
            />
            <div class="absolute w-full h-full top-0 bottom-0 left-0 right-0 flex items-center justify-center">
              <button class="bg-red ml-auto mr-auto text-white rounded-3xl pt-1 pb-1 pr-8 pl-8 font-source hover:shadow-xl transform hover:-translate-y-1" onclick="window.location.href=' https://3d.cityscaper.de/AR/Ostwall';">In AR erleben</button>
            </div>
          </div>
          <p
            class="md:col-start-2 md:col-end-3"
            v-html="$t('message.home.intro2')"
          ></p>
        </div>
        <div class="hidden relative md:flex lg:hidden ml-20 mr-20 pb-16 xl:pb-20 flex items-center justify-center">
          <img
            class=""
            src="../assets/img/webar-example.png"
            :alt="$t('message.alt.arMethod')"
          />
          <div class="absolute w-full h-full top-0 bottom-0 left-0 right-0 flex items-center justify-center">
            <button class="bg-red ml-auto mr-auto text-white rounded-3xl pt-1 pb-1 pr-8 pl-8 font-source hover:shadow-xl transform hover:-translate-y-1" onclick="window.location.href=' https://3d.cityscaper.de/AR/Ostwall';">In AR erleben</button>
           </div>
        </div>
      </div>
    </div>
    <!-- Section 2 -->
    <div class="grid grid-cols-8 gap-0 bg-gray-200 pt-32 pb-32">
      <div class="col-start-2 col-end-8">
        <h2
          class="font-sen font-bold text-lg md:text-2xl lg:text-3xl xl:text-4xl pb-16"
          v-html="$t('message.home.h2')"
        ></h2>
        <div
          class="flex flex-col md:grid md:grid-cols-2 md:gap-8 space-y-8 md:space-y-0"
        >
          <router-link
            v-for="(customer, key) in customers"
            :key="key"
            :to="customer.path"
            ><CustomerSegmentCard
              :text="customer.message"
              :icon="customer.icon"
          /></router-link>
        </div>
      </div>
    </div>
    <!-- Section Beratung -->
    <div class="mt-32 grid grid-cols-8">
      <div class="col-start-2 col-end-8 2xl:col-start-1 2xl:col-end-9 flex">
        <div class="hidden md:flex flex-none pr-10 w-3/4">
          <!-- <div class="relative ml-16 lg:ml-24 xl:ml-28 mr-16 lg:mr-24 xl:mr-28"> -->
           <iframe width=100% height=500px src="https://3d.cityscaper.de/AR/Kopernikusquartier"></iframe>
        </div>
        <div class="flex flex-1 flex-wrap content-center">
          <h2
            class="font-sen font-bold text-xl pt-4 pb-4"
            v-html="$t('message.home.consulting.title')"
          ></h2>
          <p
            class="font-source text-md"
            v-html="$t('message.home.consulting.text')"
          ></p>
          <router-link to="/Kontakt" class="mt-8 mb-8 ml-auto mr-auto">
            <button
              class="rounded-full font-sen hidden xl:flex bg-black text-white pt-3 pb-3 pr-6 pl-6 transition duration-40 ease-in-out hover:shadow-xl transform hover:-translate-y-1"
              v-html="$t('message.home.consulting.btn')"
            ></button>
          </router-link>
        </div>
      </div>
      <div class="mb-32 col-start-1 col-end-8 flex justify-center">
        <router-link to="/Kontakt">
          <button
            class="rounded-full font-sen xl:hidden mt-8 mb-8 ml-auto mr-auto bg-black text-white pt-3 pb-3 pr-6 pl-6 transition duration-40 ease-in-out hover:shadow-xl transform hover:-translate-y-1"
            v-html="$t('message.home.consulting.btn')"
          ></button>
        </router-link>
      </div>
      <!-- Section Referenzen -->
      <div ref="referenzen" id="referenzen" class="col-span-8 flex justify-center items-center">
        <ReferenceCarousell />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CustomerSegmentCard from '@/components/CustomerSegmentCard.vue'
import ReferenceCarousell from '@/components/ReferenceCarousell.vue'
import { customers } from '../Customers'
import { Head } from '@egoist/vue-head'

@Options({
  data () {
    return {
      canScrollRight: true,
      customers: customers
    }
  },
  components: {
    CustomerSegmentCard,
    ReferenceCarousell,
    Head
  },
  methods: {
    scrollTo (id: string) {
      console.log(id)
      const element = this.$refs[id]
      element.scrollIntoView()
    }
  }
})
export default class Home extends Vue {}
</script>
