<template>
<div>
  <!--div class="flex flex-col">
    <div class="relative flex flex-row justify-center" :class="{'hidden':!specialIsOpen}">
      <div class="relative text-right flex flex-col">
        <button @click="() => {$router.push({path: '/DownloadApp'}); specialIsOpen=false}" class="z-20 absolute text-center opacity-0 hover:opacity-40 bg-black w-full h-full">
          <p class="font-bold font-sen text-white text-9xl">◀</p>
        </button>
        <div class="m-8">
          <h3 class="w-full flex-none z-10 mb-4 max-w-prose md:pl-72" v-html="$t('message.special.dhdl')"></h3>
          <router-link to="/DownloadApp" target="_blank" class="flex-none z-30 border-2 border-black bg-white rounded-3xl pt-1 pb-1 pr-8 pl-8 font-source hover:shadow-xl transform hover:-translate-y-1"><button>{{ $router.resolve('/DownloadApp').name }}</button></router-link>
        </div>
        <div class="grow w-full h-full"></div>
        <img class="-mt-16 md:-mt-40 w-2/3 max-h-60 object-contain object-left-bottom flex-none" src="../assets/img/app_preview.jpg" alt="cityscaper bei Die Höhle der Löwen">
      </div>
      <div class="bg-black w-px"></div>
      <div class="relative text-left flex flex-col">
        <button @click="gotoPE" class="z-20 absolute text-center opacity-0 hover:opacity-40 bg-black w-full h-full">
          <p class="font-bold font-sen text-white text-9xl">▶</p>
        </button>
        <div class="m-8 z-10">
          <h3 class="w-full flex-none max-w-prose md:pr-72" v-html="$t('message.special.pe')"></h3>
          <div class="grow"></div>
        </div>
        <img class="-mt-16 md:-mt-40 w-2/3 ml-auto max-h-60 object-contain object-right-bottom flex-none" src="../assets/img/pe_preview.jpg" alt="Gebäude dargestellt in 3D">
      </div>
      <div class="absolute bottom-1.5 w-full flex z-30 flex-row justify-center">
        <button @click="calendarLink()" class="justify-center border-2 border-black bg-white rounded-3xl pt-1 pb-1 pr-8 pl-8 font-source hover:shadow-xl transform hover:-translate-y-1">{{$t('message.special.calendar')}}</button>
      </div>
    </div>
    <div class="bg-red text-white p-6 cursor-pointer" @click="specialIsOpen=!(specialIsOpen ??false)">
      <h3 class="font-sen font-bold text-center">{{$t('message.special.head') + " " + (specialIsOpen?"↑   ✕":"↓")}}</h3>
    </div>
  </div-->
  <div class="flex flex-row grid md:grid-cols-8 grid-cols-5 gap-0">
    <!--<div class="flex-none col-span-1 p-12 xl:p-16 xl:pt-4 xl:pb-4 2xl:p-20 2xl:pt-4 2xl:pb-4">-->
    <!--<div class="flex-none col-span-1 grid grid-cols-4 gap-0 md:pr-2 md:pl-2 lg:pr-4 lg:pl-4 xl:pl-8 xl:pr-8 2xl:pl-12 2xl:pr-12">
      <img class="object-contain h-full col-start-2 col-end-4 pb-3" src="../assets/logo_eye.svg" alt="Logo"/>
    </div>-->
    <div class="flex-grow relative">
      <!--<img class="object-contain w-full cursor-pointer" :onClick="() => {$router.push('/')}" src="../assets/logo_eye.svg" alt="Logo cityscaper"/>-->
      <img class="w-full" src="../assets/img/placeholder_square.png"/>
      <img class="absolute top-0 left-0 object-contain w-full h-full cursor-pointer m-4 md:m-0 md:pl-2 md:pr-2 md:pt-4 md:pb-4 lg:pl-4 lg:pr-4 lg:pt-6 lg:pb-6 2xl:pl-8 2xl:pr-8 2xl:pb-10 2xl:pt-10" :onClick="() => {$router.push('/')}" src="../assets/logo.svg" alt="Logo"/>
    </div>
    <div class="flex flex-row md:col-span-4 col-span-1 items-center font-source">
      <!--<router-link to="/" class="flex-none h-5 mt-auto mb-auto pr-16"><img class="h-full" src="../assets/logo_type.svg" alt="cityscaper"/></router-link>-->
      <!-- classic md link menu -->
      <div v-for="(r, i) in routes[0]" :key="i" class="hidden md:flex whitespace-nowrap flex-initial group flex-col align-middle p-2 md:pl-2 md:pr-2 lg:pl-6 lg:pr-6 text-md" >
        <router-link :to="r?.path" :class="{ 'font-bold': r?.name === $route.name }" class="self-center pl-4 pr-4 pb-2 pt-3">{{r?.name}}</router-link>
        <div :class="{ 'opacity-100':(r?.name === $route.name) }" class="relative z-10 transition duration-400 ease-in-out opacity-0 group-hover:opacity-100 w-full h-0.5 bg-red">
          <div class="absolute inset-y-0 min-w-full font-source">
            <div v-if="routes[1][r?.path.slice(1)]?.length > 0"
                 class="flex flex-col bg-white mt-1 opacity-0 group-hover:opacity-100 space-y-2 pb-4 pt-2 transform transition duration-400 ease-in-out -translate-y-1/2 group-hover:-translate-y-0 scale-y-0 group-hover:scale-y-100">
              <a class="relative cursor-pointer hover:font-bold pl-4 pr-4" v-for="(obj, idx) in routes[1][r?.path.slice(1)]" :key="idx">
                <router-link class="absolute" :to="obj.path">{{obj.name}}</router-link>
                <span class="invisible font-bold">{{obj.name}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div :class="{'opacity-100': burgerSnackOpen }" class="md:hidden col-span-1 z-30 ml-auto mt-auto mb-auto pb-3.5 uppercase font-sen transition duration-400 ease-in-out opacity-0">{{ $t('message.close') }}</div>
    </div>
    <div class="flex flex-wrap md:col-span-2 col-span-2 content-center ">
      <!--<a href="http://cityscaper.de/DownloadApp" target="_blank" rel="noopener noreferrer" class="bg-red ml-auto mr-2 whitespace-nowrap text-white rounded-3xl pt-1 pb-1 pr-8 pl-8 font-source hover:shadow-xl transform hover:-translate-y-1"><button>{{ $t('message.downloadApp') }}</button></a>-->
      <router-link to="/Kontakt" class="bg-red ml-auto mr-2 whitespace-nowrap text-white rounded-3xl pt-1 pb-1 pr-8 pl-8 font-source hover:shadow-xl transform hover:-translate-y-1"><button>{{ $router.resolve('/Kontakt').name }}</button></router-link>
    </div>
    <div class="hidden md:flex flex-wrap md:col-span-1 content-center">
      <router-link to="/DownloadApp" target="_blank" class="border-2 border-black rounded-3xl pt-1 pb-1 pr-8 pl-8 font-source hover:shadow-xl transform hover:-translate-y-1"><button>{{ $router.resolve('/DownloadApp').name }}</button></router-link>
    </div>
    <!-- mobile menu -->
    <div class="md:hidden relative flex col-span-1 cursor-pointer z-30" v-on:click="burgerSnackOpen = !burgerSnackOpen">
      <div :class="{'border-black': !burgerSnackOpen, 'border-transparent': burgerSnackOpen}" class="relative flex w-16 h-16 border-2 mt-auto mb-auto ml-auto mr-2 group pl-3.5">
        <div :class="{'rotate-45 scale-x-110': burgerSnackOpen}" class="absolute w-8 h-0.5 bg-black rounded-md top-4 transition duration-400 ease-in-out transform origin-top-left"></div>
        <div :class="{'-translate-x-full opacity-0': burgerSnackOpen}" class="self-center w-8 h-0.5 bg-black rounded-md mt-auto mb-auto transition duration-400 ease-in-out transform"></div>
        <div :class="{'-rotate-45 scale-x-110': burgerSnackOpen}" class="absolute w-8 h-0.5 bg-black rounded-md bottom-4 transition duration-400 ease-in-out transform origin-bottom-left"></div>
      </div>
    </div>
    <div :class="{'scale-x-full': burgerSnackOpen, 'scale-x-0': !burgerSnackOpen}" class="absolute flex md:hidden w-full z-20 transition duration-400 ease-in-out border-black border-2 transform origin-right bg-white pt-32 pl-16 pr-16 pb-8">
      <div v-on:click="burgerSnackOpen = !burgerSnackOpen" class="flex-col flex font-sen space-y-6 w-full">
        <router-link class="hover:font-bold text-2xl" to="/">{{$router.resolve('/').name}}</router-link>
        <div class="space-y-8" v-for="(r, i) in routes[0]" :key="i">
          <router-link   class="hover:font-bold text-2xl" :to="r.path">{{r.name}}</router-link>
          <div v-if="routes[1][r?.path.slice(1)]?.length > 0">
            <div class="mb-2 mt-2" v-for="(sub, idx) in routes[1][r?.path.slice(1)]" :key="idx">
              <router-link class="hover:font-bold text-lg pl-8" :to="sub.path">{{sub.name}}</router-link>
            </div>
          </div>
        </div>
        <router-link class="hover:font-bold text-2xl mb-16" to="/Kontakt">{{$router.resolve('/Kontakt').name}}</router-link>
        <div class="flex mt-6 space-x-8 pl-8 pr-8 justify-center">
          <a href="https://www.facebook.com/cityscaper.de/"><div class="flex-1 w-24 h-24 transform hover:-translate-y-1 bg-black fb-icon"></div></a>
          <a href="https://www.instagram.com/cityscaper.de/"><div class="flex-1 w-24 h-24 transform hover:-translate-y-1 bg-black insta-icon"></div></a>
          <a href="https://www.linkedin.com/company/cityscaper"><div class="flex-1 w-24 h-24 transform hover:-translate-y-1 bg-black linkedin-icon"></div></a>
        </div>
      </div>
    </div>
    <!-- Language select
    <div class="hidden md:flex flex-wrap md:col-span-1 content-center">
      <div class="">
        <select class="flex" v-model="$i18n.locale">
          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
        </select>
      </div>
    </div>
    -->
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue'
import router from '../router'
import { RouteRecordRaw, useRoute } from 'vue-router'

export default defineComponent({
  name: 'Header',
  components: {
  },
  setup () {
    const route = useRoute()
    const routeID = ref('')
    watch(
      () => route.name,
      newName => {
        // document.title = 'cityscaper - ' + (newName as string)
        const froute = router.options.routes.find((sroute) => sroute.name === route.name) as RouteRecordRaw
        routeID.value = (froute as any)?.props?.id
      },
      {
        immediate: true
      }
    )
    return {
      routeID
    }
  },
  data () {
    return {
      burgerSnackOpen: false,
      specialIsOpen: true
    }
  },
  methods: {
    gotoPE () {
      window.location.href = 'https://projektentwicklung.cityscaper.de/'
    },
    calendarLink () {
      window.location.href = 'https://calendar.app.google/Zfpn2v9TPzs2PD7r9'
    }
  },
  computed: {
    /* getRouteID () {
      return this.routeID?.value
    }, */
    routes () {
      const ret: any = { }
      for (const idx in router.options.routes) {
        const route: any = router.options.routes[idx]
        const path = route.path.split('/')
        if (path.length < 3) {
          if (ret[0] === undefined) {
            ret[0] = []
          }
          if ('props' in route && 'listed' in route.props && route.props.listed === true) {
            ret[0].push({ name: route.name, path: route.path })
          }
        } else {
          const level = path.length - 2
          if (ret[level] === undefined) {
            ret[level] = {}
            ret[level][path[level]] = []
          }
          if (ret[level][path[level]]) {
            ret[level][path[level]].push({
              name: route.name,
              path: route.path
            })
          }
        }
      }
      return ret
    }
  }
})
</script>

<style scoped>

</style>
