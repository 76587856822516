
import { defineComponent, watch, ref } from 'vue'
import router from '../router'
import { RouteRecordRaw, useRoute } from 'vue-router'

export default defineComponent({
  name: 'Header',
  components: {
  },
  setup () {
    const route = useRoute()
    const routeID = ref('')
    watch(
      () => route.name,
      newName => {
        // document.title = 'cityscaper - ' + (newName as string)
        const froute = router.options.routes.find((sroute) => sroute.name === route.name) as RouteRecordRaw
        routeID.value = (froute as any)?.props?.id
      },
      {
        immediate: true
      }
    )
    return {
      routeID
    }
  },
  data () {
    return {
      burgerSnackOpen: false,
      specialIsOpen: true
    }
  },
  methods: {
    gotoPE () {
      window.location.href = 'https://projektentwicklung.cityscaper.de/'
    },
    calendarLink () {
      window.location.href = 'https://calendar.app.google/Zfpn2v9TPzs2PD7r9'
    }
  },
  computed: {
    /* getRouteID () {
      return this.routeID?.value
    }, */
    routes () {
      const ret: any = { }
      for (const idx in router.options.routes) {
        const route: any = router.options.routes[idx]
        const path = route.path.split('/')
        if (path.length < 3) {
          if (ret[0] === undefined) {
            ret[0] = []
          }
          if ('props' in route && 'listed' in route.props && route.props.listed === true) {
            ret[0].push({ name: route.name, path: route.path })
          }
        } else {
          const level = path.length - 2
          if (ret[level] === undefined) {
            ret[level] = {}
            ret[level][path[level]] = []
          }
          if (ret[level][path[level]]) {
            ret[level][path[level]].push({
              name: route.name,
              path: route.path
            })
          }
        }
      }
      return ret
    }
  }
})
