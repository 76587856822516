<template>
<div class="group grid md:grid-cols-4 lg:grid-cols-3 grid-cols-3 bg-white p-4 lg:p-8 hover:shadow-2xl cursor-pointer transition duration-40 ease-in-out transform hover:-translate-y-1">
  <div class="lg:h-12 col-span-1"></div>
  <p class="md:col-span-3 lg:col-span-2 col-span-2 h-0 opacity-0 lg:group-hover:opacity-100 text-xs xl:text-sm 2xl:text-xs lg:pt-10 2xl:pt-14 xl:pt-4 transition duration-40 ease-in-out translate-y-full transform lg:group-hover:-translate-y-0 pl-3"
    v-html="$t(text + '.text')"></p>
  <div class="col-span-1 p-6 sm:p-10 md:p-2">
    <img class="h-32" :src="require('@/assets/ui/' + icon)" :alt="alt"/>
  </div>
  <div class="flex flex-wrap col-span-2 md:col-span-3 lg:col-span-2 content-center h-full">
    <div class="transition duration-40 ease-in-out opacity-100 lg:group-hover:opacity-0 transform lg:group-hover:-translate-y-full pl-3">
      <h3 class="font-sen font-bold md:text-sm xl:text-xl pb-3" v-html="$t(text + '.title')"></h3>
      <p class="text-sm lg:text-md">{{$t(text + '.subtitle')}}</p>
    </div>
  </div>
  <div class="lg:h-12 md:col-span-4 lg:col-span-3 col-span-3 flex inline-block justify-end">
      <div class="flex justify-end mt-auto p-3 group-hover:bg-black rounded-full">
      <div class="font-sen font-light text-sm text-red group-hover:text-white uppercase">{{ $t("message.more2") }}</div>
      <div class="fill-current bg-red group-hover:bg-white h-4 w-10 arrow-icon" />
    </div>
  </div>
</div>
</template>

<script type="ts">
export default {
  name: 'CustomerSegmentCard',
  props: ['text', 'icon', 'alt']
}
</script>
