
// &shy; is a hint for the site renderer where to break the word
export const customers: { [key: string]: any } = {
  Stadtplanung: {
    name: 'Stadt&shy;planung',
    path: '/Augmented-Reality/Stadtplanung',
    icon: 'icon_stadtplanung.svg',
    message: 'message.customer.stadt'
  },
  Immobilienentwicklung: {
    name: 'Immobilien&shy;entwicklung',
    path: '/Augmented-Reality/Immobilienentwicklung',
    icon: 'icon_immo.svg',
    message: 'message.customer.immo'
  }
  // Infrastrukturprojekte: {
  //  name: 'Infrastruktur&shy;projekte',
  //  path: '/Augmented-Reality/Infrastrukturprojekte',
  //  icon: 'icon_infrastrukt.svg',
  //  message: 'message.customer.infra'
  // },
  // Architektur: {
  //  name: 'Archi&shy;tektur',
  //  path: '/Augmented-Reality/Architektur',
  //  icon: 'icon_architektur.svg',
  //  message: 'message.customer.architektur'
  // }
}
