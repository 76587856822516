<template>
  <router-link :to="'/Referenzen/' + id">
    <div
      class="relative cursor-pointer rounded-24 hover:shadow-2xl transform hover:-translate-y-1 p-4"
    >
      <p
        class="absolute top-2 p-4 text-white shadow-xl font-sen uppercase text-sm"
        v-html="$t(id + '.location')"
      ></p>
      <img
        class="w-full"
        :src="require('@/assets/img/refs/' + project.thumbnail)"
        :alt="$t(id + '.thumbAlt')"
      />
      <div class="pt-2">
        <p
          class="font-sen text-xs xl:text-sm uppercase"
          v-html="$t(id + '.genre')"
        ></p>
        <h4
          class="font-sen font-bold text-md xl:text-lg"
          v-html="$t(id + '.title')"
        ></h4>
        <p
          class="font-source text-sm xl:text-md"
          v-html="$t(id + '.subtitle')"
        ></p>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { refTexts } from '../references/ReferenceStore'

export default {
  name: 'ReferenceThumb',
  props: ['project', 'id'],
  i18n: refTexts
}
</script>

<style scoped></style>
