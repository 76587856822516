<template>
  <!--<script src="https://unpkg.com/vue@next"></script>
  <script src="https://unpkg.com/vue-i18n@next"></script>-->
  <div class="2xl:pagewrap overflow-hidden">
  <Header/>
  <router-view/>
  <Footer/>
  </div>
</template>

<script lang="ts">
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default ({
  components: {
    Header,
    Footer
  }
})
</script>

<style lang="scss">
</style>
