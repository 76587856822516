<template>
  <div class="w-full grid grid-cols-8 mb-8 mt-8">
    <!--<h4 v-html="$t('message.funding.title')" class="font-sen text-xl col-start-2 col-end-8"></h4>-->
    <div class="col-start-2 col-end-8 grid grid-cols-3 md:grid-cols-6 gap-4 items-center">
      <!--<a class="col-span-1 m-1 xl:m-4" href="http://www.bmwi.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/BMWi.png" :alt="$t('message.funding.bmwiAlt')"></a>
      <a class="col-span-1 m-2 xl:m-6" href="https://www.esf.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/ESF.png" :alt="$t('message.funding.esfAlt')"></a>
      <a class="col-span-1 m-2 xl:m-6" href="https://www.esf.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/ESF_claim.png" :alt="$t('message.funding.esfClaimAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://europa.eu/european-union/index_de" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/EU.png" :alt="$t('message.funding.euAlt')"></a>-->
      <a class="col-span-1 m-4 xl:m-8" href="https://www.aachen.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/Aachen.png" :alt="$t('message.funding.Aachen')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.muenchen.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/Muenchen.png" :alt="$t('message.funding.Muenchen')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.essen.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/Essen.png" :alt="$t('message.funding.Essen')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.stadt-koeln.de" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/Koeln.png" :alt="$t('message.funding.Koeln')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.dortmund.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/Dortmund.png" :alt="$t('message.funding.Dortmund')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.leipzig.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/Leipzig.png" :alt="$t('message.funding.Leipzig')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.krefeld.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/krefeld.jpeg" :alt="$t('message.funding.krefeldAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.gelsenkirchen.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/gelsenkirchen.jpeg" :alt="$t('message.funding.gelsenkirchenAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.elsdorf.de/startseite" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/elsdorf.jpeg" :alt="$t('message.funding.elsdorfAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.leonberg.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/leonberg.jpeg" :alt="$t('message.funding.leonbergAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.oberhausen.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/oberhausen.jpeg" :alt="$t('message.funding.oberhausenAlt')"></a>
      <a class="col-span-1 m-2 xl:m-6" href="https://www.exist.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/Exist.png" :alt="$t('message.funding.existAlt')"></a>
      <a class="col-span-1 m-2 xl:m-6" href="https://www.de.digital/DIGITAL/Navigation/DE/Gruenderwettbewerb/Preistraeger/Preistraeger-Liste/preistraeger-liste.html" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/Sonderpreis_2021.png" :alt="$t('message.funding.sonderpreisAlt')"></a>
      <!-- <a class="col-span-1 m-4 xl:m-8" href="https://aachen.digital/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/hub.png" :alt="$t('message.funding.hubAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.badencampus.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/badencampus.png" :alt="$t('message.funding.badencampusAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.badencampus.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/badencampusBatch.png" :alt="$t('message.funding.badencampusBatchAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.startupbw.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/StartUpBW.png" :alt="$t('message.funding.startUpBWAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://venturevilla.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/VV.png" :alt="$t('message.funding.VVAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.rwth-innovation.de/de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/RWTHInno.png" :alt="$t('message.funding.RWTHInnoAlt')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.hjpplaner.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/HJPplaner.png" :alt="$t('message.funding.HJPplaner')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.domscheit.biz/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/ADomscheitRGB.png" :alt="$t('message.funding.Domscheit')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://medien.nrw/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/MedienNetzwerk.png" :alt="$t('message.funding.MedienNetzwerk')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://q-lab-thinking.com/de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/LogoQlab.png" :alt="$t('message.funding.QLab')"></a>
      <a class="col-span-1 m-4 xl:m-8" href="https://www.citiesforfuture.de/" target="_blank" rel="noopener noreferrer"><img src="../assets/img/foerderlogos/LogoCFF.jpg" :alt="$t('message.funding.CFF')"></a> -->
    </div>
  </div>
  <div class="bg-black w-full text-white grid grid-cols-8 pt-16 font-source">
    <div class="col-start-2 col-end-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <div class="col-start-1 col-end-2">
        <router-link to="/Kontakt" class="flex text-white w-full hover:font-bold">{{ $router.resolve('/Kontakt').name }}</router-link>
        <router-link to="/Presse" class="flex text-white text-md hover:font-bold w-full">{{ $router.resolve('/Presse').name }}</router-link>
        <router-link to="/Referenzen" class="flex text-white text-md hover:font-bold w-full">{{ $router.resolve('/Referenzen').name }}</router-link>
        <router-link to="/Startup" class="flex text-white text-md hover:font-bold w-full">{{ $router.resolve('/Startup').name }}</router-link>
      </div>
      <div class="ml-auto mr-auto w-1/2 md:w-full md:ml-0 md:mr-0 col-start-1 col-end-3 md:col-start-3 md:col-end-4 lg:col-start-4 lg:col-end-5 flex-wrap justify-items-center">
        <div class="p-12"><img class="w-100" src="../assets/logo_white.svg" /></div>
        <h5 class="text-center w-100 font-sen uppercase">{{ $t('message.claim') }}</h5>
        <div class="flex mt-6 space-x-8 pl-4 pr-4 md:pl-8 md:pr-8 justify-center">
          <a href="https://www.facebook.com/cityscaper.de/" target="_blank" rel="noopener noreferrer"><img class="flex-1 transform hover:-translate-y-1" src="../assets/ui/icon_fb.svg" /></a>
          <a href="https://www.instagram.com/cityscaper.de/" target="_blank" rel="noopener noreferrer"><img class="flex-1 transform hover:-translate-y-1" src="../assets/ui/icon_insta.svg" /></a>
          <a href="https://www.linkedin.com/company/cityscaper" target="_blank" rel="noopener noreferrer"><img class="flex-1 transform hover:-translate-y-1" src="../assets/ui/icon_linkedin.svg" /></a>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-black w-full text-white grid grid-cols-8 pt-16 font-source">
    <hr class="text-white w-100 col-span-8">
    <div class="w-100 col-start-2 col-end-8 flex flex-row mt-8 mb-8 font-sen">
      <div class="flex-1 text-left uppercase">&#169; cityscaper 2023</div>
      <div class="flex-1 text-right uppercase hover:font-bold"><router-link to="/Impressum">{{ $router.resolve('/Impressum').name }}</router-link></div>
    </div>
  </div>
</template>

<script type="ts">
export default {
  name: 'Footer',
  computed: {
    arRoutes () {
      const ret = {}
      for (const routeID in this.$router.options.routes) {
        const route = this.$router.options.routes[routeID]
        const path = route.path?.split('/')
        for (let i = 1; i < path.length; i++) {
          if (path[1] === 'Augmented-Reality') {
            if (!(path[1] in ret)) {
              ret[path[i]] = {
                title: '',
                subPages: []
              }
            }
            if (i + 1 === path.length) {
              if (i !== 1) {
                ret[path[1]].subPages.push(route.name)
              } else {
                ret[path[1]].title = route.name
              }
            }
          }
        }
      }
      return ret
    }
  }
}
</script>

<style scoped>

</style>
