<template>
  <div>
    <div class="flex flex-col md:flex-row justify-between items-center">
      <h2 class="font-sen text-2xl font-bold mb-4 md:mb-0">
        {{ $t("message.home.references.title") }}
      </h2>
      <div
        v-if="canScrollRight"
        v-bind:class="{
          'border-black': canScrollRight,
          'border-gray-200': !canScrollRight,
          'hover:shadow-xl': canScrollRight,
        }"
        class="ml-2 w-16 h-16 border-2 cursor-pointer"
      >
        <div
          v-bind:class="{
            'bg-black': canScrollRight,
            'bg-gray-200': !canScrollRight,
          }"
          class="arrow-icon w-full h-full"
          @click="scrollRight"
        ></div>
      </div>
    </div>
    <div class="relative md:flex flex-nowrap mt-16">
      <div
        class="relative flex-shrink-0 flex-grow max-w-sm"
        v-for="(id, index) in visibleReferences"
        :key="id"
      >
        <ReferenceThumb
          :project="referenceDict[id]"
          :id="id"
          :isMobileView="isMobileView"
        />
        <div v-if="isMobileView && index === 0" class="absolute inset-0"></div>
        <div
          v-if="!isMobileView && index === 2"
          v-show="!isMobileView"
          class="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-r from-transparent to-white"
        ></div>
      </div>
    </div>
    <div class="col-span-8 h-32"></div>
  </div>
</template>

<script lang="ts">
import { references } from '../references/ReferenceStore'
import ReferenceThumb from './ReferenceThumb.vue'

export default {
  name: 'ReferenceCarousel',
  components: {
    ReferenceThumb
  },
  data () {
    return {
      referenceDict: references,
      referenceIDs: Object.keys(references),
      currentIndex: 0,
      isMobileView: false
    }
  },
  computed: {
    visibleReferences (): any {
      const elementsPerScroll = this.isMobileView ? 1 : 3
      const startIndex = this.currentIndex
      const endIndex =
        (startIndex + elementsPerScroll - 1) % this.referenceIDs.length

      if (startIndex <= endIndex) {
        return this.referenceIDs.slice(startIndex, endIndex + 1)
      } else {
        return this.referenceIDs
          .slice(startIndex)
          .concat(this.referenceIDs.slice(0, endIndex + 1))
      }
    },
    canScrollRight () {
      return this.referenceIDs.length > 2
    }
  },
  methods: {
    scrollRight () {
      this.currentIndex = (this.currentIndex + 1) % this.referenceIDs.length
    },
    checkMobileView () {
      this.isMobileView = window.innerWidth <= 768 // width breakpoint
    }
  },
  mounted () {
    this.checkMobileView()
    window.addEventListener('resize', this.checkMobileView)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkMobileView)
  }
}
</script>

<style scoped></style>
