
import { Options, Vue } from 'vue-class-component'
import CustomerSegmentCard from '@/components/CustomerSegmentCard.vue'
import ReferenceCarousell from '@/components/ReferenceCarousell.vue'
import { customers } from '../Customers'
import { Head } from '@egoist/vue-head'

@Options({
  data () {
    return {
      canScrollRight: true,
      customers: customers
    }
  },
  components: {
    CustomerSegmentCard,
    ReferenceCarousell,
    Head
  },
  methods: {
    scrollTo (id: string) {
      console.log(id)
      const element = this.$refs[id]
      element.scrollIntoView()
    }
  }
})
export default class Home extends Vue {}
