export const references: { [id: string]: any } = {
  KoelnEhrenstrasse: {
    tag: 'Stadtplanung',
    thumbnail: 'EhrenstraßeAR4.jpg',
    splash: 'EhrenstrasseSplash.jpg',
    img1: 'https://www.youtube-nocookie.com/embed/lmbn3QuIXB4',
    img2: 'EhrenstraßeWebAR.jpg',
    img3: 'EhrenstraßeAR1.jpg',
    img4: 'EhrenstraßeAR2.jpg',
    img5: 'EhrenstraßeAR3.jpg',
    img6: 'EhrenstraßeAR5.jpg'
  },
  MuenchenBeNaMo: {
    tag: 'Stadtplanung',
    thumbnail: 'Benamo3D2.jpg',
    splash: 'BenamoSplash.jpg',
    img1: 'https://www.youtube-nocookie.com/embed/myuj5Mhd97k',
    img2: 'Benamo3D2.jpg',
    img3: 'Benamo3D3.jpg',
    img4: 'BenamoAR1.jpg',
    img5: 'BenamoAR2.jpg'
  },
  BOB: {
    tag: 'Projektentwicklung',
    thumbnail: 'BOBThumb.jpeg',
    splash: 'BOBSplash.jpeg',
    img1: 'https://www.youtube-nocookie.com/embed/976EEniyjdo',
    img2: 'BOBImg1.jpeg',
    img6: 'BOBImg2.jpeg',
    img3: 'BOBImg3.jpeg',
    img4: 'BOBImg4.jpeg',
    img5: 'BOBImg5.jpeg'
  },
  Weberplatz: {
    tag: 'Stadtentwicklung',
    thumbnail: 'WeberplatzThumb.jpg',
    splash: 'WeberplatzSplash.jpg',
    img1: 'WeberplatzImg1.jpg',
    img2: 'essen_gifmaker.gif',
    img3: 'WeberplatzImg3.jpg'
  },
  luetticher: {
    tag: 'Stadtplanung',
    thumbnail: 'luetticherThumb.jpg',
    splash: 'luetticherSplash.jpg',
    img1: 'luetticherImgv4.jpg',
    img2: 'luetticherImgv1.jpg',
    img3: 'luetticherImgv42.jpg'
  },
  huckarde: {
    tag: 'Stadtplanung',
    thumbnail: 'huckardeThumb.jpg',
    splash: 'huckardeSplash.jpg',
    img1: 'https://www.youtube-nocookie.com/embed/uXMgwcd4Ebg',
    img2: 'lageplan.jpg'
  },
  geilenkirchen: {
    tag: 'Projektentwicklung, Architektur',
    thumbnail: 'geilenkirchenThumb.jpg',
    splash: 'geilenkirchenSplash.jpg',
    img1: 'geilenkirchenImg1.mp4',
    img2: 'geilenkirchenImg2.jpg',
    img3: 'geilenkirchenImg3.jpg',
    img4: 'geilenkirchenImg4.jpg',
    img5: 'geilenkirchenImg5.jpg',
    img6: 'geilenkirchenImg6.jpg',
    img7: 'geilenkirchenImg7.jpg',
    img8: 'geilenkirchenImg8.jpg',
    img9: 'geilenkirchenImg9.jpg',
    img10: 'geilenkirchenImg10.jpg',
    img11: 'geilenkirchenImg11.jpg',
    img12: 'geilenkirchenImg12.jpg',
    img13: 'geilenkirchenImg13.jpg'
  },
  timeshift: {
    tag: 'Tourismus',
    thumbnail: 'timeshiftThumb.jpg',
    splash: 'timeshiftSplash.jpg',
    img1: 'timeshiftImg1.mp4',
    img2: 'timeshiftImg2.jpg',
    img3: 'timeshiftImg3.jpg',
    img4: 'timeshiftImg4.jpg',
    img5: 'timeshiftImg5.jpg',
    img6: 'timeshiftImg6.jpg',
    img7: 'timeshiftImg7.jpg',
    img8: 'timeshiftImg8.jpg',
    img9: 'timeshiftImg9.jpg',
    img10: 'timeshiftImg10.jpg'
  },
  SmartCity: {
    tag: 'Smart City',
    thumbnail: 'SCThumb.jpg',
    splash: 'SCSplash.jpg',
    img1: 'SCImg1.jpg',
    img2: 'SCImg2.jpg',
    img3: 'essen_gifmaker.gif'
  }
}

export const refTexts = {
  messages: {
    de: {
      KoelnEhrenstrasse: {
        title: 'Köln Ehrenstraße',
        genre: 'Stadtplanung',
        subtitle: 'Lebenswerte Zukunftsvision',
        location: 'Köln',
        thumbAlt: 'Augmented Reality Zukunftsvision der Ehrenstraße',
        splashAlt: 'Augmented Reality Visualisierung Ehrenstraße',
        time: 'ab Mai 2023',
        partner: '<a href="https://www.stadt-koeln.de/">Stadt Köln</a>',
        t1: 'Mit der Umgestaltung der Ehrenstraße möchte die Stadt Köln nicht nur den Verkehr in ' +
        'der geschäftigen Einkaufstraße reduzieren, sondern auch die Lebensqualität erhöhen. ' +
        'Nachdem die Stadt die Straße 2022 als Fußgängerzone ausgewiesen hat, schließt sich in den kommenden Jahren ' +
        'ihre bauliche Neugestaltung an. Damit soll sich der steinerne Stadtraum in einen grünen Boulevard ' +
        'für den Fuß- und Radverkehr verwandeln. Um die Transformation für alle Bürger:innen erlebbar zu machen, wurde die ' +
        'von <a href="https://scape-net.de/">scape Landschaftsarchitekten</a> entwickelte Planung in Augmented Reality dargestellt. Somit können alle Beteiligten in die Zukunft vor Ort eintauchen. ',
        t2: '<h3>Einsatz</h3>Da die Umgestaltung der Straße die Anzahl der verfügbaren Parkplätze reduziert ' +
        'und eine hohe Komplexität aufweist, wurde die Kommunikationsstrategie um den Einsatz von Augmented Reality ' +
        'erweitert. <br /><br /> Ascan Egerer, Beigeordneter für Mobilität stellt fest: "Im Rahmen von Beteiligungsformaten ' +
        'ist zukünftig beispielsweise denkbar, Bürger:innen mittels AR über verschiedene Ausbauvarianten befinden zu lassen. Die Einblicke in die zukünftige ' +
        'Gestaltung der Ehrenstraße beeindrucken jedenfalls schon heute und zeigen anschaulich, was für ein hochwertiger Stadtraum hier entstehen wird."',
        t3: '<h3>User Experience</h3>In gemeinsamer Zusammenarbeit mit dem Kölner Dezernat für Mobilität und dem Landschaftsarchitekturbüro ' +
        'wurde die Planung für Augmented Reality aufbereitet und in der cityscaper App zur Verfügung gestellt. ' +
        'Vor Ort können Bürger:innen die App öffnen und landen automatisch in der immersiven AR-Erfahrung. Anschließend kann man sich 800 Meter durch die zukünftige Straße bewegen und einfach staunen.',
        t4: '',
        imgDescription1: 'Köln Ehrenstraße AR Video',
        imgAlt1: 'Köln Ehrenstraße AR Video Stadtplanung',
        imgDescription2: 'WebAR Visualisierung Kölner Ehrenstraße',
        imgAlt2: 'WebAR Visualisierung Kölner Ehrenstraße Stadtplanung',
        imgDescription3: 'Blick Apostelstraße AR-Visualisierung Ehrenstraße',
        imgAlt3: 'Blick Apostelstraße AR-Visualisierung Ehrenstraße Stadtplanung',
        imgDescription4: 'Willy-Millowitsch-Platz AR-Visualisierung Ehrenstraße',
        imgAlt4: 'Willy-Millowitsch-Platz AR-Visualisierung Ehrenstraße Stadtplanung',
        imgDescription5: 'Blick in Ehrenstraße AR-Visualisierung Ehrenstraße',
        imgAlt5: 'Blick in Ehrenstraße AR-Visualisierung Ehrenstraße Stadtplanung',
        imgDescription6: 'Bürger entdeckt AR-Visualisierung Ehrenstraße',
        imgAlt6: 'Bürger entdeckt AR-Visualisierung Ehrenstraße Stadtplanung',
        seo: {
          title: 'Stadtplanung mit Augmented Reality in der Kölner Ehrenstraße',
          description: 'Kölner Ehrenstraße - Planungen mit Augmented Reality kommunizieren',
          keywords: 'Stadtplanung, Augmented Reality, Bürgerbeteiligung'
        }
      },
      MuenchenBeNaMo: {
        title: 'München Westenriederstraße',
        genre: 'Stadtplanung',
        subtitle: 'Co-kreative Stadtplanung',
        location: 'München',
        thumbAlt: 'Augmented Reality Beteiligung Westenriederstrasse',
        splashAlt: 'Augmented Reality Visualisierung Westenriederstrasse',
        time: 'ab September 2023',
        partner: '<a href="https://www.muenchen.de/">Stadt München</a>',
        t1: 'Die Stadt München plant die Umgestaltung der Westenriederstraße in eine Fußgängerzone, ' +
        'um sowohl den Verkehr in der belebten Innenstadt zu reduzieren, als auch die Aufenthaltsqualität langfristig zu erhöhen.  ' +
        'Um Bürger:innen eine positive Zukunftsvision aufzuzeigen und gleichzeitig die Bedarfe der Menschen zu berücksichtigen, ' +
        'nutzt die Stadt München Augmented Reality. Im Rahmen des vom <a href="https://nexusinstitut.de/">nexus Institut</a> geleiteten Forschungsprojekts "BeNaMo" ' +
        'bekommen Bürger:innen die Möglichkeit, einen Blick mittels AR in die Zukunft zu wagen und weitere 3D-Objekte wie Stadtmöbel und weitere Begrünungselemente zu platzieren.',
        t2: '<h3>Einsatz</h3>In enger Abstimmung mit der Stadt München wurde eine mögliche Zukunftvision der Westenriederstraße in verkehrsberuhigter Form ' +
        'mittels Augmented Reality dargestellt. Während der IAA 2023 bewirbt die Stadt die Möglichkeit mit einem öffentlichen Stand in der Westenriederstraße. ' +
        'Angeleitet von Beteiligungsexpert:innen haben Bürger:innen die Möglichkeit, die Vision mit Ihrem eigenen Endgerät zu erleben und anschließend eigene Gestaltungsvorschläge abzugeben. ' +
        'So können Bäume gepflanzt, zusätzliche Sitzgelegenheiten positioniert und als Entwurf an die Stadt München übermittelt werden. ' +
        'Zusätzlich können Kommentare innerhalb der Planung platziert werden, sodass die Stadt München Anmerkungen und Bedenken aufnehmen und in der Planung berücksichtigen kann.',
        t3: '<h3>User Experience</h3>Um möglichst viele Bürger:innen zu erreichen, organisiert die Stadt München den ' +
        'öffentlichen Stand während der international bekannten Messe. Die Kombination aus aufsuchender Beteiligung mit digitalen Elementen ist insbesondere für die Beteiligung von Jugendlichen geeignet. ' +
        'Nach dem Download der cityscaper App können Bürger:innen bequem einen Blick in die Zukunft wagen, ihre Meinung kundtun und Gestaltungsvorschläge einreichen. Somit wird aus analoger Stadtplanung begeisternde Bürgerbeteiligung.',
        t4: '',
        imgDescription1: 'München Westenriederstraße AR Video',
        imgAlt1: 'München Westenriederstraße AR Video Stadtplanung',
        imgDescription2: 'Augmented Reality Visualisierung München Westenriederstraße',
        imgAlt2: 'Augmented Reality Visualisierung München Westenriederstraße Stadtplanung',
        imgDescription3: 'Mobilitätsstation Westenriederstraße AR-Visualisierung',
        imgAlt3: 'Mobilitätsstation Westenriederstraße AR-Visualisierung Stadtplanung',
        imgDescription4: 'AR-Visualisierung München Westenriederstraße',
        imgAlt4: 'AR-Visualisierung Muenchen Westenriederstraße Stadtplanung',
        imgDescription5: 'AR-Visualisierung München Westenriederstraße Mobilitätsstation',
        imgAlt5: 'AR-Visualisierung Muenchen Westenriederstraße Mobilitätsstation Stadtplanung',
        seo: {
          title: 'Stadtplanung mit Augmented Reality in der Münchener Westenriederstraße',
          description: 'Münchener Westenriederstraße - Beteiligung mit Augmented Reality erweitern',
          keywords: 'Stadtplanung, Augmented Reality, Bürgerbeteiligung'
        }
      },
      BOB: {
        title: 'BOB.Düsseldorf Airport City',
        genre: 'Projektentwicklung',
        subtitle: 'Nachhaltiges Bürogebäude',
        location: 'Düsseldorf',
        thumbAlt: 'BOB.Düsseldorf in Aachen',
        splashAlt: 'Augmented Reality Projektentwicklung BOB.Düsseldorf',
        time: 'ab 2022',
        partner: '<a href="https://www.bob-ag.de/">BOB</a>',
        t1: 'BOB-Bürogebäude bestechen durch hohe Klimafreundlichkeit, perfekten Raumklimakomfort und ' +
        'moderne Arbeitswelten. BOBs Architekten entwickeln hochwertiges und individuell angepasstes ' +
        'Design. Erleben Sie am Bauplatz in der Airport City schon heute die attraktive Architektur eines ' +
        'wegweisenden Gebäudes mit der cityscaper App.',
        t2: '<h3>Einsatz</h3>Neue BOB-Arbeitswelten entstehen bis Sommer 2024 in der attraktiven Airport City im Umfeld ' +
        'international renommierter Unternehmen. Erleben Sie mit dieser App die spannende Architektur von ' +
        'Chapman Taylor in der virtuellen Ansicht in echter Umgebung. Was Sie hier sehen, generiert die App ' +
        'direkt aus dem digitalen Modell, mit dem das BOB-Planungsteam das Bürogebäude in Perfektion ' +
        'entwickelt. Die Fassade aus recyceltem Aluminium erwecken wir ebenso zum Leben, wie den ' +
        'Innenhof und die künftige Freiraumgestaltung.',
        t3: '<h3>User Experience</h3>Gemeinsam mit BOB wurde die User Experience der App für den Vermarktungseinsatz in der Projektentwicklung grundlegend überarbeitet. ' +
        'Über einen QR-Code gelangen Besucher:innen direkt vom Bauzaun in die Zukunft. Nach dem App Download finden die Nutzer:innen über die Projektkachel in das BOB Projekt. ' +
        'In einem eingerichteten Bereich lokalisiert sich das Gerät der Nutzer:innen innerhalb von Sekunden und zeigt das Modell an. Anschließend kann das gesamte Gelände begangen, ' +
        'der attraktive Innenhof erlebt oder ein Blick durch das Fenster des BOBs geworfen werden.',
        t4: '',
        imgDescription1: 'BOB.Düsseldorf Video der BOB AG',
        imgAlt1: 'BOB.Düsseldorf Video der BOB AG Projektentwicklung',
        imgDescription2: 'BOB.Düsseldorf Blick in den Innenhof',
        imgAlt2: 'BOB.Düsseldorf Blick in den Innenhof Projektentwicklung',
        imgDescription6: 'Durchgang durch den BOB.Düsseldorf zum Innenhof',
        imgAlt6: 'Durchgang durch den BOB.Düsseldorf zum Innenhof Projektentwicklung',
        imgDescription3: 'Blick von draußen in den Innenhof',
        imgAlt3: 'Blick von draußen in den Innenhof Projektentwicklung',
        imgDescription4: 'Gastronomie vor dem BOB.Düsseldorf',
        imgAlt4: 'Gastronomie vor dem BOB.Düsseldorf Projektentwicklung',
        imgDescription5: 'Begrünung am BOB.Düsseldorf',
        imgAlt5: 'Begrünung am BOB.Düsseldorf Projektentwicklung',
        seo: {
          title: 'Projektentwicklung mit Augmented Reality BOB.Düsseldorf',
          description: 'BOB.Düsseldorf - Projektentwicklung neu vermarkten mit Augmented Reality',
          keywords: 'Projektentwicklung, Augmented Reality, Bürogebäude'
        }
      },
      Weberplatz: {
        title: 'Neugestaltung des Weberplatz in Essen',
        genre: 'Stadtentwicklung',
        subtitle: 'Mitgestaltung von Lebensraum',
        location: 'Essen, Weberplatz',
        thumbAlt: 'Augmented Reality Stadtentwicklung Weberplatz',
        splashAlt: 'Augmented Reality Stadtentwicklung Weberplatz',
        time: '02.03.2023 bis 17.03.2023',
        partner: '<a href="https://www.eon-stiftung.com/">E.ON Stiftung</a> und <a href="https://www.essen.de/aktuell.de.html">Stadt Essen</a> ',
        t1: 'Der Weberplatz in Essen liegt direkt im Stadtkern und bildet eine Brücke zwischen der Universität und der Innenstadt. Um diesen Ort zu einem besseren Lebensraum für die Bürger:innen zu machen, wird dieser neu gestaltet. Gefördert durch die E.ON Stiftung und in Zusammenarbeit mit der Stadt Essen begleiten wir von cityscaper diesen Prozess. Die Stimme und lokale Expertise von Bürger:innen wird vor Ort digital erfasst und entfaltet durch die direkte Weitergabe an Entscheider:innen eine größere Wirkung. <br> <br> Dazu wird die Augmented Reality-Lösung von cityscaper genutzt, die es ermöglicht, mithilfe eines Handys oder Tablets die bauliche Veränderung sichtbar zu machen und gleichzeitig in einer virtuellen Ebene Anmerkungen und Kommentare zur Gestaltung zu hinterlassen.',
        t2: '<h3>Einsatz</h3>Mithilfe unserer Augmented Reality-Lösung, bekommen Bürger:innen die Möglichkeit, mit ihren Handys oder Tablets die bauliche Veränderung zu sehen. Auf einer virtuellen Ebene können Markierungen gesetzt werden und Anmerkungen, Kommentare oder Wünsche hinterlassen werden. Das gesammelte Feedback wird im Anschluss an Planer:innen weitergeleitet, welche dann Entwürfe für den Weberplatz erstellen. Zusätzlich wird cityscaper eine Visualisierung der Klimadaten anbieten, um Bürger:innen über die aktuelle mikroklimatische Situation aufzuklären. So wollen wir eine Stadt und einen Raum gestalten, der den Wünschen und Bedürfnissen der Bürger:innen entspricht und gleichzeitig zukunftsweisend, klimaverträglich gestaltet ist.',
        t3: '<h3>Veranstaltung</h3>Kommt gerne vorbei. Die Aktion findet in Zusammenarbeit mit der Stadt Essen vom 02.03. bis zum 17.03.2023 statt. Am 02.03.2023 begleiten die Stadt und cityscaper in einem Praxisworkshop Bürger:innen bei der Eingabe ihrer Ideen. Sowohl klassisch mit Stift und Papier, als auch digital begleitet von cityscapers AR-Expert:innen können Ideen und Anregungen für die zukünftige Gestaltung des Platzes abgegeben werden. Zusätzlich veranstaltet die Stadt Essen am 09.03.2023 eine Informationsveranstaltung und einen Planungsworkshop im Forum Kunst und Architektur in der nördlichen Innenstadt. Ende des Sommers wird eine Jury unter Berücksichtigung eurer Eingaben einen Sieger des Wettbewerbs küren und die Planungsvarianten werden in einer Ausstellung und Präsentation vorgestellt.',
        t4: '',
        imgDescription1: 'Kommentarsystem in Augmented Reality',
        imgAlt1: 'Kommentieren, AR, Augmented Reality, Stadtplanung',
        imgDescription2: 'Begrünung unterstützt ein angenehmes Klima',
        imgAlt2: 'Begrünung unterstützt ein angenehmes Klima Stadtplanung AR',
        imgDescription3: 'Augmented Reality in der Stadtplanung',
        imgAlt3: 'Augmented Reality in der Stadtplanung',
        seo: {
          title: 'Stadtplanung mit Augmented Reality Essen',
          description: 'Weberplatz in Essen - Bürger:innen planen in Augmented Reality',
          keywords: 'Stadtplanung, Augmented Reality, Platzgestaltung'
        }
      },
      luetticher: {
        title: 'Verkehrsplanung Lütticher Straße',
        genre: 'Verkehrsplanung',
        subtitle: 'Querschnittsplanung - Straßenraum gerecht aufteilen',
        location: 'Aachen',
        thumbAlt: 'Augmented Reality Verkehrsplanung an der Lütticher Straße in Aachen Variante 1',
        splashAlt: 'Augmented Reality Verkehrsplanung an der Lütticher Straße in Aachen Variante 4',
        time: 'Januar 2021',
        partner: '<a href="https://www.aachen.de/Stadt">Stadt Aachen</a>',
        t1: 'Die Lütticher Straße ist eine der zentralen Einfallstraßen in die Stadt Aachen. Aufgrund von anstehenden ' +
          'Arbeiten durch den lokalen Versorgungsbetrieb, soll die Straße neu gestaltet werden. Die Verkehrsplanung steht ' +
          'an dieser Stelle vor Nutzungskonflikten: Anwohner:innen wollen die bestehenden Bäume erhalten, aber auch ihren ' +
          'Parkraum nicht verlieren. Der Radentscheid schreibt der Verwaltung die Etablierung von Radinfrastruktur ' +
          'vor und gleichzeitig sollen Premiumfußwege entstehen.',
        t2: '<h3>Einsatz</h3>In der Betrachtung wurden 4 verschiedene Versionen der Straße dargestellt und verglichen. ' +
          'In der App kann über ein Interface Element zwischen den verschiedenen Planungsvarianten gewechselt werden. Als' +
          'Verkehrsplaner:in kann man so vor Ort schnell und einfach die Unterschiede zwischen den geplanten Varianten ' +
          'darstellen. Die Gespräche mit den Beteiligten basieren auf einer objektiven Darstellungen, die vor Ort die ' +
          'Auswirkungen der Planung auf die Umgebung darstellen.',
        t3: '<h3>Ergebnisse</h3>Zu Beginn der Beteiligung war die Stimmung aufgeheizt und Bürger:innen fühlten sich ' +
          'übergangen. Durch eine insgesamt gelungen Beteiligungsprozess, dem reduzieren der Emotionen und eine ' +
          'Versachlichung der Diskussion konnten konstruktive Gespräche geführt werden. Die Beteiligung mündete in ' +
          'eine 5. Variante, welche einen Kompromiss zwischen den verschiedenen Interessen optimal abbildet.',
        t4: '',
        imgDescription1: 'Lütticher Straße Variante 4',
        imgAlt1: 'Augmented Reality Verkehrsplanung an der Lütticher Straße in Aachen Variante 4',
        imgDescription2: 'Lütticher Straße Variante 1',
        imgAlt2: 'Augmented Reality Verkehrsplanung an der Lütticher Straße in Aachen Variante 1',
        imgDescription3: 'Lütticher Straße Variante 4',
        imgAlt3: 'Augmented Reality Verkehrsplanung an der Lütticher Straße in Aachen Variante 4 aus einer anderen Ansicht',
        seo: {
          title: 'Verkehrsplanung Lütticher Straße',
          description: 'Die Lütticher Straße in Augmented Reality für konflikfreie Bürgerbeteiligung',
          keywords: 'Verkehrsplanung, Radverkehr, Verkehrsplaner'
        }
      },

      huckarde: {
        title: 'Verweilorte sollen neu gestaltet werden – Machen Sie mit!',
        genre: 'Freiraumgestaltung',
        subtitle: 'Freiflächen verbinden - Sitzmöbel für Huckarde',
        location: 'Dortmund Huckarde',
        thumbAlt: 'Huckarde, Park, Freiraumgestaltung, 3 Sitzgelegenheiten',
        splashAlt: 'Plakat Dortmund Huckarde Beteiligungsaktion Wo möchten Sie Platz nehmen?',
        time: 'Dezember 2021',
        partner: '<a href="https://www.dortmund.de/de/leben_in_dortmund/planen_bauen_wohnen/stadterneuerung/projekte_stadterneuerung/stadterneuerung_huckarde/stadtumbaugebiet_huckarde_nord/stadtumbaugebiet_huckarde_nord.html" target="_blank" rel="noopener noreferrer">Stadt Dortmund</a>',
        t1: '<span class="font-bold mb-2">Der Fehler in der Android Version ist behoben. Nach einer Aktualisierung über den Play Store freuen wir uns auf Ihre Teilnahme an der Beteiligung.</span><br><br>Die Vorbereitungen für die Erneuerung von drei Spielplätzen im Stadtumbaugebiet Huckarde-Nord laufen bei der Stadt Dortmund auf Hochtouren: Der Wettbewerb für die Gestaltung der Spielplätze „Mailoh“, „Varziner Straße“ und „In der Mulde“ wurde 2020 abgeschlossen, seither werden die Planungen weiter konkretisiert. Ergänzend zur Erneuerung der Spielplätze sollen sie besser miteinander verbunden werden und der Aufenthalt entlang dieser Verbindungsachse attraktiver gestaltet werden. Durch den Umbau der Spielplätze und ihre Verknüpfung miteinander sollen neue gestalterische, soziale und ökologische Impulse für den Stadtteil gesetzt werden und diese einen Mehrwert für das Quartier schaffen. <br> <br> Ein zentrales Element bilden dabei die neuen Sitzgelegenheiten, damit die Menschen zusätzliche Orte zum Verweilen im Quartier bekommen. Bei deren Planung möchte das Amt für Stadterneuerung gern die Meinung der Huckarder:innen einbeziehen. Machen Sie mit!',
        t2: '<h3>Verwendungshinweis</h3>Dazu laden Sie die „cityscaper“-App aus dem App Store oder dem Google Play Store herunter. Nach der Installation starten Sie die cityscaper App und wählen in der Übersicht die Projektkachel mit dem Titel <b>„Wo möchten Sie Platz nehmen?“</b>. Nach einer kurzen Ladezeit sehen Sie das Kamerabild. Bewegen Sie sich nun zu einem der <b>auf der <a href="../lageplan.jpg" class="underline hover:text-gray-500" target="_blank" rel="noopener noreferrer" Karte>Karte</a> markierten Punkte</b>. Sie erkennen diese im Park anhand der <b>cityscaper Sticker</b>. Starten Sie nun den Papierflieger und folgen Sie ihm mit dem Kamerabild. Das Mobilgerät erkennt nun die Umgebung. Sobald der Lokalisierungsvorgang abgeschlossen ist, werden Ihnen die entsprechenden Flächen zum Platzieren von Objekten angezeigt. Klicken Sie dazu auf das <b>Plus Symbol</b> in der unteren rechten Ecke. Platzieren Sie diese nun nach Belieben in die vorgegebenen Flächen. <br><br>Wenn Ihr Entwurf fertig gestaltet ist, können Sie diesen abspeichern. Dafür klicken Sie im oberen Bereich des Bildschirms auf Speichern. Sie können über das Einstellungsmenü in der rechten oberen Ecke den AR Modus verlassen. Das gespeicherte Projekt taucht jetzt bei Ihnen in der App als neue Kachel auf. Sie können diese erneut aufrufen und Ihren Entwurf weiterbearbeiten.<br><br><b>Wichtig!</b> Ihr Fortschritt wird nur hochgeladen und gespeichert, wenn Sie während der Verwendung der App den <b>Speichern Knopf drücken</b>. Anschließend werden Ihre Änderungen bei vorhandener Internetverbindung laufend abgespeichert.',
        t3: '<h3>Prozess</h3>Die Karte mit den Kalibrierungsorten finden Sie auf den Informationsbannern an der Kleingartenanlage Glückauf Hansa zur Aktion. Hier können Sie auch den QR-Code für die Installation der App scannen. Sobald Sie sich an einem der mit cityscaper Sticker markierten Orte kalibriert haben, zeigt die App die Bereiche, in denen die neuen Sitzgelegenheiten entstehen sollen. Pro Bereich können Sie wiederum in der App aus verschiedenen Sitzmöbeln wählen und diese in den markierten Bereichen platzieren. Es gibt beispielsweise Bänke für zwei Personen, Einzelsitzplätze oder auch Bänke, die Menschen mit Rollatoren zum Pause machen einladen. Dabei sind pro Ort maximal drei, für die größeren vorausgewählten Sitzbereiche Sitze für maximal sechs Personen platzierbar.<br><br>Ihnen wird dabei mit jedem ausgewählten Sitzplatz überschlägig angezeigt, welche Kosten durch die getroffene Auswahl ungefähr entstehen würden und wie sich dies auf das Budget des Projekts auswirken würde.<br><br>Speichern Sie ihre Auswahl in der App, werden Ihre Vorschläge automatisch in die Gesamtauswertung einbezogen. Sie müssen dafür keine persönlichen Daten hinterlegen, die Übermittlung Ihrer Ideen erfolgt anonym. <br><br>Bei der Aktion können Sie von <b>Freitag, 17. Dezember 2021</b> bis einschließlich <b>Sonntag, 23. Januar 2022</b> mitmachen. Die eingereichten Ideen werden anschließend zusammengetragen und fließen in die weiteren Planungen für die Verweilorte im Quartier ein.',
        t4: '<h3 class="font-sen font-bold text-2xl"> Technischer Support</h3>' +
        '<li>Verlassen Sie den AR Modus über das Einstellungsmenü. <b>Starten Sie Ihr gespeichertes Projekt über die entsprechende Kachel neu</b> und kalibrieren Sie sich anschließend nochmals.</li>' +
        '<li><b>Starten Sie die App neu</b>, indem Sie diese über das Übersichtsmenü ihres Handys vollständig beenden und anschließend neu öffnen.</li>' +
        '<li>Wenn Sie sich nicht erfolgreich wiederfinden können, halten Sie das Handy etwa 2 Meter vom Sticker entfernt und bewegen Sie sich kreisförmig um den Sticker herum. Bewegen Sie Ihr Gerät dabei möglichst langsam und gleichmäßig und halten Sie den Sticker im Mittelpunkt ihres Kreises.</li><br>' +
        'Sollten Sie weiterhin Probleme bei der Verwendung der App haben, kontaktieren Sie den <b>technischen Support von cityscaper</b> per Mail oder Telefon:' +
        '<br><br>' +
        'support[at]cityscaper.de<br>' +
        '+49 176 81232910',
        imgDescription1: 'Gestalten Sie den Freiraum neu!',
        imgAlt1: 'Augmented Reality Fahrradständer Huckarde Dortmund Bürgerbeteiligung Freiraumplanung',
        imgDescription2: 'Lageplan der Punkte zum Wiederfinden und Bereiche zum Platzieren von Sitzgelegenheiten',
        imgAlt2: 'Plan, auf dem die neune Sitzgelegenheiten markiert sind an denen man sich Wiederfinden kann und neue Sitzgelegenheiten platzieren kann.',
        // imgDescription3: 'Gestalten Sie den Freiraum neu!',
        // imgAlt3: 'Augmented Reality Fahrradständer Huckarde Nord Bürgerbeteiligung Freiraumplanung',
        seo: {
          title: 'Huckarde Freiraumplanung Bürgerbeteiligung',
          description: 'Wo möchten Sie Platz nehmen? Beteiligungsaktion in Dortmund Huckarde',
          keywords: 'Freiraumplanung, Augmented Reality, Dortmund'
        }
      },
      geilenkirchen: {
        title: 'Nachverdichtung Geilenkirchen',
        genre: 'Wohnungsbau',
        subtitle: 'Nachhaltige Projektentwicklung nachhaltig präsentieren',
        location: 'Geilenkirchen',
        thumbAlt: 'Wohnungsbau in Geilenkirchen aus der isometrischen Ansicht',
        splashAlt: 'Ansicht auf das Gesamtvorhaben von der Straße auf Personenhöhe aus dem Webviewer',
        time: 'April 2021',
        partner: '<a href="https://www.future-flats.de/">Future Flats</a>',
        t1: 'Der Projektentwickler Future Flats möchte in Geilenkirchen eine Nachverdichtung realisieren. Hierzu sind vier Gebäude ' +
          'mit insgesamt 40 Wohnungen geplant. Von Seiten der Politik und Verwaltung gab es Rückfragen zur Verträglichkeit eines der Gebäude. ' +
          'Zusammen mit cityscaper entwickelte der Projektentwickler eine anschauliche 3D Viewer im Web. In diesem können die Sichtbeziehungen der neuen Gebäude zu angrenzenden Erholungsgebieten objektiv betrachtet werden. ' +
          'Durch diese interaktive Vermittlung der Neubebauung im Vergleich zur Altbebauung konnten die Fragen in den Sitzungen mit Politik und Verwaltung jederzeit durch passende Ansichten überzeugend beantwortet werden.',
        t2: '<h3>Zeitplan</h3>Future Flats beauftragte cityscaper rund 8 Tage vor der entscheidenen Sitzung im Planungsausschuss der Stadt. ' +
          'Trotz des kurzen Zeithorizonts setzte cityscaper die bestehende Planung überzeugend im 3D Viewer und in Bildern für meine Präsentation um. ' +
          'Die Zeit reichte darüber hinaus für 2 Feedbackschleifen, in denen noch Einzelheiten am Modell nachträglich geändert wurden.',
        t3: '<h3>Testimonial</h3>"Aus anfänglicher Neugier entwickelte sich eine konstruktive Partnerschaft mit positiven Ergebnissen. Schnittstellenprobleme beim Datenaustausch der vorgelegten Planung wurden flexibel, professionell und in kurzer Zeit umgesetzt. Die Visualisierungen unterstützten uns bei der Projektvorstellung im Stadtentwicklungsausschuss. Mit den weiteren Entwicklungsideen zur virtuellen Darstellung des Vorhabens erwarten wir neue Möglichkeiten zur Konzipierung von Marketingkampagnen. Wir freuen uns auf die weitere Zusammenarbeit und Weiterentwicklung." <br> <b> Bert Dreßen, Geschäftsführer</b>',
        t4: '',
        imgDescription1: 'Wohnungsbau in Geilenkirchen vor Ort in Augmented Reality',
        imgAlt1: 'Wohnungsbau in Geilenkirchen vor Ort in Augmented Reality',
        imgDescription2: 'Wohnungsbau in Geilenkirchen im Webviewer aus Bürger:innensicht',
        imgAlt2: 'Wohnungsbau in Geilenkirchen im Webviewer Draufsicht',
        imgDescription3: 'Wohnungsbau in Geilenkirchen im Webviewer Draufsicht mit Umgebungsbebauung',
        imgAlt3: 'Wohnungsbau in Geilenkirchen im Webviewer aus Bürger:innensicht mit Umgebungsbebauung',
        imgDescription4: 'Wohnungsbau in Geilenkirchen im Webviewer aus Bürger:innensicht',
        imgAlt4: 'Wohnungsbau in Geilenkirchen im Webviewer aus Bürger:innensicht',
        imgDescription5: 'Wohnungsbau in Geilenkirchen vor Ort mit Augmented Reality perfekt in die Umgebung eingepasst',
        imgAlt5: 'Wohnungsbau in Geilenkirchen vor Ort mit Augmented Reality perfekt in die Umgebung eingepasst',
        imgDescription6: 'Blick in die Grünanlage',
        imgAlt6: 'Blick in die Grünanlage',
        imgDescription7: 'Blick auf die Tiefgarageneinfahrt',
        imgAlt7: 'Blick auf die Tiefgarageneinfahrt',
        imgDescription8: 'Blick aus der Ferne mit Umgebungsbebauung',
        imgAlt8: 'Blick aus der Ferne mit Umgebung',
        imgDescription9: 'Ansicht von oben auf das Gesamtvorhaben',
        imgAlt9: 'Ansicht von oben auf das Gesamtvorhaben',
        imgDescription10: 'Ansicht vor Ort in Augmented Reality die Straße runter',
        imgAlt10: 'Ansicht vor Ort in Augmented Reality die Straße runter',
        imgDescription11: 'Weitere Ansicht von oben im Webviewer',
        imgAlt11: 'Wohnungsbauvorhaben von oben',
        imgDescription12: 'Blick auf Höhe des 2. Geschosses auf das Bauvorhaben',
        imgAlt12: 'Blick auf einen Wohnungsbau auf Höhe des 2. Geschosses im Webviewer',
        imgDescription13: 'Blick aus der Ferne auf das Vorhaben eingefügt in die Umgebung',
        imgAlt13: 'Blick aus der Ferne auf das Vorhaben',
        seo: {
          title: 'Nachverdichtung im Wohnungsbau',
          description: 'Visualisierung eines Bauvorhabens in Geilenkirchen. Nachhaltige Nachverdichtung in einer Bestandsbebauung.',
          keywords: 'Wohnungsbau, Nachverdichtung, Projektentwicklung Visualisierung'
        }
      },
      timeshift: {
        title: 'Augmented Reality im Tourismus in Düsseldorf',
        genre: 'Tourismus',
        subtitle: 'timeshift - an Ort und Stelle durch die Geschichte reisen',
        location: 'Düsseldorf',
        thumbAlt: 'Mann mit Smartphone, im unteren Bereich ein weiteres Smartphone, dass die Vergangenheit der Umgebung anzeigt.',
        splashAlt: 'Tonhalle in Düsseldorf mit einer X-Ray Ansicht in Augmented Reality überlagert',
        time: 'Juli bis Oktober 2020',
        partner: '<a href="https://www.duesseldorf-tourismus.de/">Düsseldorf Tourismus</a>,<br><a href="https://digihub.de/">Digital Innovation Hub Düsseldorf</a>,<br><a href="https://www.huawei.com/de/">Huawei</a>',
        t1: '11 App-Entwickler:innen entwickelten in 2 Monaten eine neue App mit Augmented Reality App im Tourismus. Huawei als Technologiepartner ' +
          'stellte Software-Bibliotheken und Hardware zur Verfügung. Düsseldorf Tourismus formulierte die Herausforderung: Wie wird Augmented Reality im Tourismus verwendet? Wie kann Augmented Reality das Erlebnis der Tourist:innen vor Ort von Grund auf revolutionieren?',
        t2: '<h3>Augmented Reality erweitert die Realität</h3> timeshift lässt im Tourismus Besucher:innen die Vergangenheit intuitiv erleben. Vor Ort wird über ein einfaches Wischen über den Bildschirm von Epoche zu Epoche gereist. ' +
          'Von der Ausgangssituation, über den Bau bis hin zur Zerstörung im 2. Weltkrieg. timeshift macht Geschichte erlebbar. ' +
          'Darüber hinaus können Besucher:innen mit virtuellen Figuren interagieren, mit Augmented Reality in Gebäude hineinschauen oder auch direkt ein Ticket für eine Attraktion buchen.',
        t3: '<h3>Können auch Sie Augmented Reality im Tourismus einsetzen?</h3>Ganz klar ja! Zusammen mit Düsseldorf Tourismus haben wir gezeigt, dass sich auch im Tourismus tolle Use-Cases im Tourismus umsetzen lassen. ' +
          'Mit neuer Gamification, eine Vorschlagssystem für das nächste Restaurant oder in Ihre Tourismusapp vor Ort integriert erfahren Tourist:innen die Vorteile Ihrer Stadt. ' +
          'Machen auch Sie Ihre Region attraktiv und präsentieren Sie Ihre einzigartige Geschichte. Aber vielleicht wollen Sie auch die Zukunft zeigen. Dann schauen Sie doch mal auf unser <a href="https://cityscaper.de/Augmented-Reality/Stadtplanung">Stadtplanungsseite</a> vorbei.',
        t4: '',
        imgDescription1: 'Mit timeshift kann man einfach durch die Zeit reisen, indem man über den Bildschirm wischt.',
        imgAlt1: 'Video, wie man in einer Augmented Reality App als Tourist:in durch die Zeit reisen kann.',
        imgDescription2: 'InApp Ansicht auf die Kunsthalle in Düsseldorf wie sie um 1900 aussah.',
        imgAlt2: 'InApp Ansicht auf die Kunsthalle in Düsseldorf wie sie um 1900 aussah.',
        imgDescription3: 'Nachdem man in Augmented Reality die Geschichte der einzelnen Sehenswürdigkeiten erfahren hat, kann man direkt sein Ticket buchen.',
        imgAlt3: 'App Screen mit Augmented Reality X-Ray auf dem man ein Ticket buchen kann',
        imgDescription4: 'Animierte Augmented Reality Darstellung der zerstörten Tonhalle nach dem 2. Weltkrieg.',
        imgAlt4: 'App Screen mit Augmented Reality Animation, die die Tonhalle in Düsseldorf zerstört zeigt.',
        imgDescription5: 'Weitere Ansicht der zerstörten Tonhalle mit Rauchanimation',
        imgAlt5: 'cityscaper, Augmented Reality, Tourismus, 2. Weltkrieg, Zerstörung, App, Tonhalle Düsseldorf',
        imgDescription6: 'App Ansicht beim shiften vor 1920 gemischt mit nach 1920 der Kunsthalle in Düsseldorf',
        imgAlt6: 'cityscaper, Augmented Reality, Tourismus, App, Kunsthalle Düsseldorf, Geschichte',
        imgDescription7: 'Blick ins innere der Tonhalle mit Informationen zu besonders bekannten Kunstwerken.',
        imgAlt7: 'cityscaper, Augmented Reality, Tourismus, Kunsthalle Düsseldorf, App, Kunst',
        imgDescription8: 'Interaktives Interface der App, um Kunstwerke aus der Kunsthalle in der eigenen Umgebung zu platzieren, zu skalieren und Fotos für Social Media zu machen.',
        imgAlt8: 'cityscaper, Augmented Reality, Tourismus, Interaktion, Gamification, App',
        imgDescription9: 'Gamification in der App, sodass man mit den virtuellen Kunstwerken einschlagen kann.',
        imgAlt9: 'cityscaper, Augmented Reality, Tourismus, Handshake, Gamification, App, Social Media',
        imgDescription10: 'Bild der animierten Straßenbahn der Gesolei vor der Tonhalle',
        imgAlt10: 'cityscaper, Augmented Reality, Tourismus, Animation, Gesolei, App',
        seo: {
          title: 'Augmented Reality im Tourismus',
          description: 'timeshift lässt Gäst:innen vor Ort in Augmented Reality durch die Zeit Reisen. Nutzen auch Sie Augmented Reality im Tourismus.',
          keywords: 'Augmented Reality im Tourismus'
        }
      },
      SmartCity: {
        title: 'Augmented Reality für Smart City Projekte',
        genre: 'Stadtentwicklung',
        subtitle: 'Smarte Städte mit AR und Bürgerbeteiligung',
        location: 'Modellprojekte Smart City',
        thumbAlt: 'AR in der Smart City',
        splashAlt: 'Augmented Reality in der Praxis',
        time: '2023',
        partner: 'Modellkommunen',
        t1: 'Städte und Stadtplaner können dank der Anwendung von Augmented Reality (AR) in Smart City Projekten ihre Visionen und Pläne für die Zukunft auf eine interaktive und anschauliche Art präsentieren. Mit der cityscaper AR-App ermöglichen wir, komplexe Pläne durch interaktive 3D-Visualisierungen zu verdeutlichen und dadurch eine bessere Verständlichkeit und Akzeptanz zu schaffen. Nutzen Sie die Vorteile von AR, um Ihre Visionen realitätsnah und verständlich zu vermitteln.',
        t2: '<h3>Anwendungsbeispiele</h3>Was kann man mit AR in Smart City-Kontext machen? Eine erfolgreiche Stadtentwicklung erfordert die Einbeziehung von Stakeholdern und eine effektive Kommunikation mit der Bevölkerung. Augmented Reality bietet hierfür eine hilfreiche Lösung, indem es ermöglicht, Stadtentwicklungsprojekte zu visualisieren und ihren Einfluss auf Umgebung und Bevölkerung besser zu verstehen. Zudem kann AR genutzt werden, um Klimadaten zu visualisieren und Hitzeinseln zu identifizieren, um ein besseres Klima in Städten zu erreichen. Diese Technologie im Smart City-Kontext eröffnet somit neue Möglichkeiten für eine verbesserte Stadtplanung und -gestaltung im Sinne der Bewohner:innen.',
        t3: '<h3>Aktualität</h3>Dank Augmented Reality wird Smart City noch smarter. Städte werden noch effizienter, klimaschonender und lebenswerter. Entscheidungsträger:innen, Bürger:innen und Investoren:innen profitieren gleichermaßen von der Technologie, da sie komplexe Planungen und Konzepte sichtbar macht und eine Vorstellung von den Auswirkungen auf die Umgebung vermittelt. Augmented Reality wird damit zu einem wichtigen Tool, um die Stadt der Zukunft zu gestalten.',
        t4: '',
        imgDescription1: 'AR macht die Smart City bürgernah',
        imgAlt1: 'AR macht die Smart City bürgernah',
        imgDescription2: 'Augmented Reality macht komplexe Planung sichtbar',
        imgAlt2: 'Augmented Reality in der Smart City Bürgerbeteiligung',
        imgDescription3: 'Klimadaten in der Smart City',
        imgAlt3: 'Klimadaten, Daten, Umweltdaten, Smart City',
        seo: {
          title: 'Augmented Reality für Smart City Projekte',
          description: 'Was kann man mit AR in Smart City-Kontext machen?',
          keywords: 'Smart City, AR, Augmented Reality, Stadtplanung, Bürgerbeteiligung'
        }
      }
    }
  }
}
