
import { references } from '../references/ReferenceStore'
import ReferenceThumb from './ReferenceThumb.vue'

export default {
  name: 'ReferenceCarousel',
  components: {
    ReferenceThumb
  },
  data () {
    return {
      referenceDict: references,
      referenceIDs: Object.keys(references),
      currentIndex: 0,
      isMobileView: false
    }
  },
  computed: {
    visibleReferences (): any {
      const elementsPerScroll = this.isMobileView ? 1 : 3
      const startIndex = this.currentIndex
      const endIndex =
        (startIndex + elementsPerScroll - 1) % this.referenceIDs.length

      if (startIndex <= endIndex) {
        return this.referenceIDs.slice(startIndex, endIndex + 1)
      } else {
        return this.referenceIDs
          .slice(startIndex)
          .concat(this.referenceIDs.slice(0, endIndex + 1))
      }
    },
    canScrollRight () {
      return this.referenceIDs.length > 2
    }
  },
  methods: {
    scrollRight () {
      this.currentIndex = (this.currentIndex + 1) % this.referenceIDs.length
    },
    checkMobileView () {
      this.isMobileView = window.innerWidth <= 768 // width breakpoint
    }
  },
  mounted () {
    this.checkMobileView()
    window.addEventListener('resize', this.checkMobileView)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkMobileView)
  }
}
