import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import './css/index.css'
import { messages } from './locales/locale'
import { createHead } from '@egoist/vue-head'

const i18n = createI18n({
  locale: 'de', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages
})

const head = createHead()

createApp(App)
  .use(router)
  .use(i18n)
  .use(head)
  .mount('#app')
