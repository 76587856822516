export const messages = {
  en: {
    message: {
      hello: 'hello world'
    }
  },
  de: {
    message: {
      hello: 'Hallo Test',
      flavor: 'Flavor text describing usage',
      claim: 'Ein Blick in die Zukunft.',
      claim2: 'Um etwas wirklich zu begreifen,<br>müssen wir es sehen.',
      downloadApp: 'Download App',
      more1: 'Mehr sehen',
      more2: 'Mehr erfahren',
      close: 'Schließen',
      back: 'zurück',
      seo: {
        home: {
          title: 'Digitale Bürgerbeteiligung mit Augmented Reality in der Stadtplanung',
          description: 'cityscaper visualisiert Projektentwürfe mit Augmented Reality und baut sie absolut ' +
              'realistisch in die Wirklichkeit vor Ort ein.',
          keywords: 'Stadtplanung, cityscaper, Augmented Reality'
        },
        try: {
          title: 'Probiere Web AR für die Baubranche aus!',
          description: 'Mit cityscaper erhalten Sie überzeugende WebAR Modelle direkt von Ihren Gebäuden',
          keywords: 'Baubranche, cityscaper, Web AR'
        },
        presse: {
          title: 'Presseinformationen für Journalisten zum Startup cityscaper aus Aachen',
          description: 'Hier finden Sie Downloads, Ressourcen und Pressemitteilungen des Startups cityscaper',
          keywords: 'Presse, Journalismus, Downloads'
        },
        ar: {
          title: 'Wie kann Augmented Reality in der Stadtplanung eingesetzt werden?',
          description: 'Augmented Reality macht Bauvorhaben vor Ort sichtbar. Erleben Sie die Zukunft bereits jetzt an Ort und Stelle.',
          keywords: 'Augmented Reality, AR, Augmented Reality apps'
        },
        architektur: {
          title: 'Entwürfe mit Augmented Reality zum Leben erwecken!',
          description: 'Ein Blick in die Zukunft, der Ihre Bauherr:innen begeistert. Überzeugen Sie in AR mit cityscaper.',
          keywords: 'Architektur, Bauprojekt'
        },
        immo: {
          title: 'Erleichtern Sie Ihren Kund:innen die Suche nach einem attraktiven Investment.',
          description: 'Bauprojekte präsentiert in digitaler High End Qualität. Mit cityscaper sichern Sie sich die Unterstützung aller Beteiligten.',
          keywords: 'Immoblienentwicklung, Bauprojekt'
        },
        kontakt: {
          title: 'Lassen Sie sich vom cityscaper Team beraten.',
          description: 'Kontaktieren Sie das Team von cityscaper. Bei uns sind Sie mit Fragen zu AR, VR und Stadtplanung genau an der richtigen Stelle.',
          keywords: 'AR, VR, Augmented Reality, Virtual Reality'
        },
        datschu: {
          title: 'Datenschutz und Impressum',
          description: 'Haben Sie Fragen zum Datenschutz? Wir helfen Ihnen gerne persönlich weiter und erläutern, wie wir Ihre Daten sicher verwahren.',
          keywords: 'AR, VR, Augmented Reality, Virtual Reality'
        },
        leistungen: {
          title: 'Kommunizieren Sie Ihr Bauprojekt auf allen Kanälen.',
          description: 'AR, VR, Informationsplatform oder Medienpool - der Funktionsumfang von cityscaper wird individuell auf Ihr Projekt angepasst.',
          keywords: 'Augmented Reality, AR, Bauprojekt, Stadtplanung'
        },
        stadtplanung: {
          title: 'SmartCity Stadtplanung mit Augmented Reality - mit Bürgerbeteiligung begeistern.',
          description: 'Wir unterstützen die Stadtplanung dabei, Räume zu entwickeln, helfen, Partizipation zu gestalten und Menschen zu inspirieren. Moderieren Sie Ihre Bürgerbeteiligung in der Stadtplanung mit AR.',
          keywords: 'Stadtplanung, Partizipation, Bürgerbeteiligung'
        },
        DownloadePage: {
          title: 'cityscaper App',
          description: 'Laden Sie sich die kostenlose cityscaper App herunter. cityscaper visualisiert Projektentwürfe mit Augmented Reality und baut sie absolut',
          keywords: 'Aachen, App, Bürgerbeteiligung, cityscaper'
        },
        startup: {
          title: 'Innovation aus Aachen für die Städte der Zukunft.',
          description: 'Wir sind das Team von cityscaper, dem Startup für Augmented Reality in Aachen. Kontaktiert uns, wenn ihr Fragen zu AR, VR oder der Startup City Aachen habt.',
          keywords: 'Aachen, Startup, Start-Up, cityscaper'
        }
      },
      alt: {
        arIllustration: 'Illustration Frau nutzt cityscaper',
        arMethod: 'Grafik über die Funktionsweise von Augmented Reality',
        iconStadtplanung: 'Icon Stadtplanung',
        iconArchitektur: 'Icon Architektur',
        iconImmo: 'Icon Immobilienentwicklung',
        iconInfrastruktur: 'Icon Infrastruktur',
        title: 'Augmented Reality in der Stadtplanung mit cityscaper',
        appScreen01: 'Bildschirmaufnahme von cityscaper mit Stadthäusern'
      },
      Error: {
        title: 'Die gesuchte Seite existiert nicht.',
        text: 'Leider konnten wir diese Seite nicht finden. Vielleicht wurde der gesuchte Artikel verschoben und der Link ist veraltet. ' +
          'Bei fragen schreiben Sie uns gerne unter: info&commat;cityscaper.de'
      },
      references: {
        time: 'Zeitraum',
        location: 'Ort',
        partner: 'Kooperationen',
        previous: 'vorheriger Artikel',
        next: 'nächster Artikel'
      },
      article: {
        time: 'Datum',
        location: 'Ort',
        partner: 'Kooperationen',
        previous: 'vorheriger Artikel',
        next: 'nächster Artikel'
      },
      home: {
        h1: 'Wer die Zukunft mit eigenen Augen erlebt, ist leichter zu überzeugen.',
        h2: 'Präsentieren Sie Ihre Planung mit Augmented Reality vor Ort und in 3D auf Ihrer Website.',
        intro1: 'Ob Neuaufteilung des Straßenraumes oder Umgestaltung eines Viertels zum 15-Minuten Quartier - die Transformation unserer Städte gelingt nur mit überzeugenden Visionen, die transparent an alle Beteiligten kommuniziert werden.',
        intro2: '<b>cityscaper</b> projiziert Ihre Planung dreidimensional in die Umgebung und macht sie interaktiv online erlebbar. Die automatisierte Umwandlung komplexer Planungsdaten in verständliche Projektvisionen ermöglicht eine neue Kommunikation auf Augenhöhe - zwischen Politik, Bürgerschaft und Bauträger:innen.',
        consulting: {
          title: 'Unverbindliche Beratung',
          text: 'Wir zeigen Ihnen gern, wie Sie <b>cityscaper</b> in Ihrem Projekt einsetzen können. ' +
            'In einem persönlichen Gespräch beraten wir Sie selbstverständlich kostenlos und unverbindlich' +
            ' – auch telefonisch oder per Videocall.',
          btn: 'Jetzt Termin vereinbaren',
          download: 'Jetzt App downloaden'
        },
        references: {
          title: 'Referenzen'
        }
      },
      presse: {
        title: 'Presse',
        subtitle: 'Begeistern Sie Ihre Leser:innen mit spannendem Startup-Content.',
        imgAlt: 'Zeitung, Presse, Journalismus, cityscaper',
        text: 'Hier finden Sie Hintergrundinformationen und Fotos für die Berichterstattung zum Download sowie unsere aktuellen Pressemitteilungen.',
        text2: 'Gerne nehmen wir Sie auch in unseren Presseverteiler auf.',
        text3: 'Wir freuen uns auf Ihre Anfragen!',
        h2: 'Pressekontakt',
        contact: 'Yannic Schwarz',
        contact2: 'cityscaper GmbH',
        contact3: 'schwarz[at]cityscaper.de',
        learnMore: 'Pressemitteilungen',
        subtitle2: 'Presse über uns',
        pressArticles: '<li><a href="https://www.aachen.ihk.de/blueprint/servlet/resource/blob/5058254/695f655f9cdc3d94d8ba37974404627a/wn-03-2021-data.pdf" class="underline hover:text-gray-500" target="_blank" rel="noopener noreferrer">Wirtschaftliche Nachrichten, März 2021:</a></li>' +
          '<li><a href="https://nrw.baumeister-online.de/fileadmin/user_upload/BDB-NRW/user_upload/BDB-012021web.pdf" class="underline hover:text-gray-500" target="_blank" rel="noopener noreferrer">BDB Bund Deutscher Baumeister, Architekten und Ingenieure e.V., 1. Quartal 2021</a></li>',
        subtitle3: 'Kurzbeschreibung cityscaper',
        info: 'Unsere Städte stehen vor einer großen Transformation. Was passiert mit dem Einzelhandel? Wie bringen wir nachhaltige Mobilität in die Stadt? Und wie schaffen wir die Konversion alter Parkhaus- oder Kasernenflächen? <br> <br>' +
        'Eine erfolgreiche Transformation gelingt nur, wenn wir alle Beteiligten mitnehmen - Bürger:innen, Gewerbe und Industrie, Verwaltung und Politik. cityscaper ist die erste Kommunikationsplattform, die einen interaktiven Meinungsaustausch in den entscheidenden Phasen des Planungsprozesses möglich macht. cityscaper stellt virtuelle Projektentwürfe leicht verständlich in 3D da. Ob im Web, zuhause in der App oder mit Augmented Reality absolut real in der Wirklichkeit vor Ort. Interessant und begeisternd werden die Auswirkungen der Planung vermittelt. Doch Kommunikation ist keine Einbahnstraße. Verschiedene Entwürfe werden auf der Plattform anschaulich gegenübergestellt und Anregungen von Beteiligten können realistisch in 3D skizziert und dokumentiert werden.<br>' +
        '<b>cityscaper.</b> Ein Blick in die Zukunft.',
        subtitle4: 'Downloads',
        downloads: '<li><a href="./logo.svg" class="underline hover:text-gray-500" target="_blank" rel="noopener noreferrer" download>Logo</a></li>' +
        '<li><a href="./cityscaperBilder.zip" class="underline hover:text-gray-500" target="_blank" rel="noopener noreferrer" download>Bilder Mappe</a></li>',
        disclaimer: '<br>Das obige Bildmaterial dürfen Sie gerne für Berichterstattungen im Zusammenhang mit cityscaper unter Angabe des Copyrights aus der beigefügten Copyright Liste frei verwenden. Bitte senden Sie uns einen Beleglink an '
      },
      customer: {
        stadt: {
          title: 'Stadtplanung',
          subtitle: 'Machen Sie aus Bürgerbeteiligung – Bürgerzusammenarbeit!',
          text: '<b>cityscaper</b> präsentiert komplexe Bauvorhaben für alle verständlich, ' +
            'transparent und unterstützt den interaktiven Meinungsaustausch mit Bauherr:innen, ' +
            'Politik und Öffentlichkeit. Die App kann Anregungen und Bedenken der  Zielgruppen ' +
            'aufgreifen und unmittelbar umsetzen...'
        },
        architektur: {
          title: 'Architektur',
          subtitle: 'Ein Blick in die Zukunft, der Ihre Bauherr:innen begeistert!',
          text: '<b>cityscaper</b> transformiert Ihre Pläne in die dritte Dimension – direkt vor Ort. ' +
            'Ihre Formensprache, die Auswahl der  Materialien oder jedes Detail in der Bemusterung, ' +
            'kann auf der Baustelle erlebt und diskutiert werden...'
        },
        immo: {
          title: 'Im&shy;mo&shy;bi&shy;li&shy;en&shy;ent&shy;wick&shy;lung',
          subtitle: 'Überzeugen Sie Investor:innen und begeistern Sie mit Ihrer Vision.',
          text: 'Die Baustelle ist noch Wiese, trotzdem kann Ihr Entwurf schon dreidimensional erlebbar vor Ort stehen. ' +
            'Ideale Bedingungen, um die ersten Mieter:innen zu gewinnen...'
        },
        infra: {
          title: 'Infrastrukturprojekte',
          subtitle: 'Virtuelle Realität sagt mehr als 1000 Worte - das überzeugt!',
          text: '<b>cityscaper</b> präsentiert Infrastrukturprojekte einer großen Öffentlichkeit überzeugend anschaulich. ' +
            'Die gewonnene  Transparenz beschleunigt Genehmigungsverfahren und nimmt Betroffenen die Angst...'
        }
      },
      funding: {
        title: 'ist im Bild',
        bmwiAlt: 'Logo BMWi',
        sonderpreisAlt: 'Logo BMWK Sonderpreis digitale Städte und Kommunen 2021',
        esfClaimAlt: 'Zusammen. Zukunft. Gestalten.',
        esfAlt: 'Logo Europäischer Sozialfonds für Deutschland.',
        euAlt: 'Logo Europäische Union',
        existAlt: 'Logo Exist Stipendium',
        hubAlt: 'Logo wir sind dabei - DigitalHub Aachen',
        badencampusAlt: 'Logo BadenCampus Baden',
        badencampusBatchAlt: 'Teilnahme Batch BadenCampus Baden',
        RWTHInnoAlt: 'Logo RWTHInnovation Aachen',
        VVAlt: 'Logo VentureVilla Hannover',
        startUpBWAlt: 'Logo StartUp BW',
        SpinOffAlt: 'Logo RWTH Spin Off Award, Rheinisch Westfälisch Technische Hochschule Aachen',
        HJPplaner: 'Logo HJPplaner',
        Domscheit: 'Logo A. Domscheit',
        MedienNetzwerk: 'Logo Mediennetzwerk NRW, Virtual Reality, Augmented Reality, Kunst',
        CFF: 'Logo Cities for Future, Partner, Landschaftsplanung Hamburg',
        QLab: 'Logo QLab, ThinkTank, Partner, Co-Creation',
        Aachen: 'Logo Stadt Aachen, Partner',
        Koeln: 'Logo Stadt Köln, Partner',
        Muenchen: 'Logo Stadt München, Partner',
        Essen: 'Logo Stadt Essen, Partner',
        Dortmund: 'Logo Stadt Dortmund, Partner',
        Leipzig: 'Logo Stadt Leipzig, Partner',
        elsdorfAlt: 'Logo Stadt Elsdorf, Partner',
        krefeldAlt: 'Logo Stadt Krefeld, Partner',
        gelsenkirchenAlt: 'Logo Stadt Gelsenkirchen, Partner',
        oberhausenAlt: 'Logo Stadt Oberhausen, Partner',
        leonbergAlt: 'Logo Stadt Leonberg, Partner'
      },
      AR: {
        title: 'Augmented Reality',
        p1: 'In der Altstadt soll ein Parkhaus abgerissen und durch eine Wohnanlage ersetzt werden. Wie wird das  aussehen? ' +
          'Verändert das geplante Brückenprojekt wirklich die historische Silhouette der Altstadt? Wo wird die Verkehrstrasse im ' +
          'Stadtviertel verlaufen und wie muss man sich das neue Radwegekonzept darin vorstellen? ' +
          'Der Windpark soll angeblich zwei Kilometer vor der Gemeinde liegen, aber ist die Aussicht nicht trotzdem  verschandelt?',
        h2: 'Jedes Bauvorhaben ist eine Herausforderung an die Vorstellungskraft aller Beteiligten.',
        p2: 'Stadtplanung moderiert Veränderungsprozesse und möchte die Bürgerschaft involvieren. ' +
          'Architekturbüros und Immobilienentwickler:innen wollen Bauherr:innen und Investor:innen begeistern. ' +
          'Infrastrukturprojekte sollen transparent sein und überzeugen. Alle Interessengruppen investieren viel Zeit und noch mehr Geld und  ' +
          'kämpfen trotzdem jeden Tag gegen Mißverständnisse, Vorurteile oder Ängste, weil vielen Menschen die  Vorstellungskraft fehlt. ',
        h3: 'Deshalb haben wir die Augmented Reality App cityscaper entwickelt.',
        p3: 'cityscaper holt Ihre Ideen und Visionen in die Realität vor Ort. Expert:innen und Laien diskutieren auf  Augenhöhe miteinander und reden nicht aneinander vorbei. ' +
          'Dadurch erleichtert und entspannt cityscaper schwierige Entscheidungsprozesse.',
        learnMore: 'Erfahren Sie mehr über das Potential von cityscaper in Ihrem Bereich.',
        benefits: 'Die Vorteile',
        conclusion: 'Unser Fazit:',
        getInTouch: {
          h1: 'Lernen Sie cityscaper unverbindlich kennen.',
          p1: 'Gerne überzeugen wir Sie in einem persönlichen Gespräch,  welche Vorteile Ihnen die Augmented Reality App bringt – auch telefonisch oder per Videocall.'
        },
        downloadApp: {
          h1: 'Jetzt cityscaper App herunterladen.',
          p1: 'Sie finden unsere App im Google Play und App Store. In der App erleben Sie die unterschiedlichen AR Projekte. Einfach die richtige Kachel auswählen und Zukunft erleben!'
        },
        others: 'Erfahren Sie mehr über das Potential von cityscaper in Ihrem Bereich:',
        //
        architektur: {
          title: 'Architektur',
          subtitle: 'Starten Sie den Gebäudedialog schon in der Entwurfsphase.',
          text: 'Wie fügt sich Ihre Vision in den Gesamtkontext vor Ort ein? Welche Auswirkung hat das Sonnenlicht auf die Profilierung und Materialität der Fassade? ' +
            'Mit der Augmented Reality App cityscaper können Sie Ihre eigenen Ideen noch in der Entwurfsphase überprüfen. ' +
            'Ihr Projektteam, aber auch die Gewerke, sind sofort im Bild und das Allerbeste – Ihre Bauherr:innen begreifen und erleben die räumlichen Zusammenhänge in der Realität vor Ort! ',
          imgAlt: 'Ansicht aus einem virtuellen Haus in Augmented Reality auf einen Platz.',
          h2: 'Die Musterfassade war gestern.',
          p2: 'Ziegel, Schiefer oder doch lieber Holz? Sie müssen keine Musterfassade mehr aufmauern oder über das passende Material erst am Rohbau entscheiden. Schon vor dem ersten Spatenstich ' +
            'können Sie mit dem Bauherr:innen direkt vor Ort in die Bemusterung gehen. Das spart Zeit und erhöht die Kostensicherheit. ' +
            'Der Blick durch das Tablett vermittelt Ihren Kund:innen nicht nur einen absolut realistischen 3D Eindruck. ' +
            'Diese sind auch in der Lage, den virtuellen Neubau im Dialog bzw. Kontext zur realen Umgebung vor Ort zu erleben und zu begreifen.',
          // Benefits
          b1: '<b>cityscaper</b> zeigt Ihre Vision im Kontext zur realen Umgebung.',
          b2: '<b>cityscaper</b> erleichtert bzw. verkürzt die Bemusterung und erhöht so die Kostensicherheit.',
          b3: '<b>cityscaper</b> setzt frühzeitig Bauherr:innen ins Bild. Räumliche Zusammenhänge werden klar.',
          b4: '<b>cityscaper</b> ist BIM kompatibel.',
          b5: '<b>cityscaper</b> kann auf jedes Smartphone oder Tablet heruntergeladen werden. Das erspart teure VR-Brillen.',
          t1: 'Ihre Vision im Kontext zur Realität.',
          t2: 'Einfaches Bemustern vor Ort.',
          t3: 'Ein Link und Kund:innen teilen Ihre Vision.',
          // t3: 'Fassadenprofil im day & night check.',
          altT1: 'Wiese mit virtuellen Bürogebäuden',
          altT2: 'Änderungen am virtuellen Modell mit cityscaper',
          altT3: 'Website Mockup',
          conclusion: 'Mit cityscaper geht Ihr Büro den nächsten konsequenten Schritt in die Digitalisierung und erhöht damit seine Wettbewerbsfähigkeit.'
        },
        //
        stadtplanung: {
          title: 'Stadtplanung',
          subtitle: 'Quartiere planen, Ideen teilen, Zukunft erleben!',
          text: 'Welchen Beitrag kann die Digitalisierung leisten, um die Arbeit der Stadtplanung zu erleichtern? ' +
            'Wie können Planfeststellungsverfahren transparenter, konfliktfreier und erfolgreicher durchlaufen werden? ' +
            'Womit gewinnen Bauherr:innen und Politik beim finalen Entwurf und auch in den Detailfragen mehr Entscheidungssicherheit?  ',
          imgAlt: 'Ansicht aus einem virtuellen Haus in Augmented Reality auf einen Platz.',
          h2: 'Moderieren Sie Stadtplanung in einer neuen Dimension.',
          p2: 'cityscaper ist die erste AR Applikation, die einen interaktiven Meinungsaustausch mit der Öffentlichkeit, Politik und Bauherr:innen in den entscheidenden Phasen des Planungsprozesses möglich macht. ' +
            'Entwürfe werden  absolut real in die Wirklichkeit vor Ort eingepasst und können aus jedem beliebigen Blickwinkel betrachtet  werden. ',
          // Benefits
          b1: '<b>cityscaper</b> schafft Akzeptanz für das Projekt und räumt Missverständnisse aus dem Weg.',
          b2: '<b>cityscaper</b> fördert die interaktive Zusammenarbeit auf Augenhöhe.',
          b3: '<b>cityscaper</b> macht den Vergleich unterschiedlicher Entwürfe möglich.',
          b4: '<b>cityscaper</b> ist flexibel. Änderungswünsche werden vor Ort diskutiert und umgesetzt.',
          b5: '<b>cityscaper</b> ist spektakulär. „AR“ begeistert die Öffentlichkeit und lädt zum Mitgestalten ein.',
          b6: '<b>cityscaper</b> erleichtert die Dokumentation aller Anregungen und Bedenken der Zielgruppen.',
          t1: 'Ideen teilen. Zukunft erleben!',
          t2: 'Interaktives Menü nutzen.',
          t3: 'Änderungswünsche direkt umsetzen.',
          t4: 'Entwürfe vergleichen.',
          t5: 'Ihr Projekt als Infowebsite',
          t6: '1 Klick zur fertigen Dokumentation',
          altT1: 'Perspektive durch ein Tablet auf ein Parkhaus',
          altT2: 'Änderungen am virtuellen Modell mit cityscaper',
          altT3: 'Ein Stadthaus wird interaktiv mit cityscaper in der Höhe angepasst.',
          altT4: 'Zwei Stadtplanungsvarianten gegenübergestellt',
          altT5: 'Microsite Mockup',
          altT6: 'Virtuelle Sitzgruppe vor neuer Planung',
          conclusion: 'Mit cityscaper werden aus Bürgerbeteiligung Bürgerzusammenarbeit und aus schwierigen  Diskussionen souveräne Entscheidungen.'
        },
        //
        immobilienentwicklung: {
          title: 'Immobilienentwicklung',
          subtitle: 'Erleichtern Sie Ihren Kund:innen die Suche nach einem attraktiven Investment.',
          text: 'Sie setzen Impulse, erkennen Investment- und Entwicklungspotenziale, bewerten Chancen und entdecken Handlungsspielräume. ' +
            'Für Ihre Kund:innen leisten Sie täglich Überzeugungsarbeit und die können wir Ihnen jetzt erleichtern - mit der Augmented Reality App cityscaper.',
          imgAlt: 'Ansicht aus einem virtuellen Haus in Augmented Reality auf einen Platz.',
          h2: 'High-Street-Objekte präsentiert in digitaler High End Qualität.',
          p2: 'Mit cityscaper können Ihre Kund:innen geplante Quartiere, Wohnkomplexe oder High-Street-Objekte unmittelbar in der Realität vor Ort erleben. ' +
            'Ihre Projekte lassen sich per Handy oder Tablet dreidimensional und aus jeder Perspektive begutachten. ' +
            'Die App bringt mehr Transparenz in das Planfeststellungsverfahren, räumt Missverständnisse aus dem Weg und erhöht die öffentliche Akzeptanz. ' +
            'Ideale Bedingungen, um Investor:innen von Qualität und langfristiger Wertsteigerung zu überzeugen.',
          // Benefits
          b1: 'Schon vor Baubeginn können Sie Ihre Kund:innen begeistern.',
          b2: 'Mehr Akzeptanz für Ihr Projekt in der Öffentlichkeit.',
          b3: 'Unterschiedliche Entwürfe können miteinander verglichen werden.',
          b4: 'Die App verbessert die Kommunikation im Planfeststellungsverfahren.',
          b5: '<b>cityscaper</b> kann von jedem auf Smartphone oder Tablet verwendet werden.',
          t1: 'Ein Blick in die Zukunft',
          t2: 'Varianten gegenüberstellen',
          t3: 'Transparenz schaffen',
          altT1: 'Perspektive durch ein Tablet auf ein Parkhaus',
          altT2: 'Zwei Entwürfe aus der Vogelperspektive gegenübergestellt',
          altT3: 'Plakat vor einer Baustelle mit QR code'
        }
      },
      Leistungen: {
        title: 'Leistungen',
        subtitle: 'tagline',
        text: 'cityscaper ist modular aufgebaut, um individuell auf Ihre Anforderungen einzugehen. Wir haben die 3 beliebtesten Konfigurationen in Szenarien für Sie zusammengefasst. Lassen Sie aber gerne Ihrer Kreativität freien Lauf.',
        dict: {
          example: 'Beispiel',
          scenario: 'Szenario'
        },
        s1: {
          title: 'Quar&shy;tier&shy;s&shy;ent&shy;wick&shy;lung',
          fullTitle: 'Quartiersentwicklung “Wohnen und Einkaufen”',
          tagline: 'Zukunftswerkstatt',
          description: 'Planen Sie eine interaktive Werkstatt bei der alle gemeinsam eine Vision entwickeln. Jung und alt, ' +
            'Menschen mit internationaler Geschichte und diversem sozialen Hintergrund - erhalten Sie ein umfassendes Bild über die Bedürfnisse der Stadtnutzer:innen. ' +
            'Begeistern Sie jeden oder jede sich in den Prozess einzubringen.'
        },
        s2: {
          title: 'Mo&shy;bi&shy;li&shy;täts&shy;kno&shy;ten&shy;punkt',
          fullTitle: 'Bebauungsplan Mobilitätsknotenpunkt',
          tagline: 'Vorentwurfsplanung',
          description: 'Vergleichen Sie verschiedene Varianten in der Vorentwurfsplanung und überzeugen Sie alle, dass Ihr Vorhaben das Lebensumfeld nachhaltig verbessert. ' +
            'Ihre Geschichte in der cityscaper App verdeutlicht Randbedingungen und Hintergründe, die die Notwendigkeit für eine Veränderung klar werden lassen.'
        },
        s3: {
          title: 'Re&shy;vi&shy;ta&shy;li&shy;sie&shy;rung',
          fullTitle: 'Stadtteilzentrum Mixed-Use-Konzept',
          tagline: 'Revitalisierung',
          description: 'Sie werten Flächen auf und hauchen Stadtteilen neues Leben ein. cityscaper kombiniert alt und neu für Sie und setzt Ihre Pläne eindrucksvoll in der Bestandsbebauung in Szene. ' +
            'Machen Sie Ihre Planungen transparent und überzeugen Sie Verwaltung, Politik, Investor:innen und Anwohner:innen gleichermaßen.'
        },
        /* gestrichen
        process: {
          title: 'Wir begleiten Sie Schritt für Schritt',
        },
        */
        //
        //
        //
        app: {
          title: 'Die App',
          subtitle: 'Ihr Blick in die Zukunft vor Ort',
          bullets: ['Grundlage und Plattform für alle weiteren Funktionen',
            'Augmented Reality Darstellung vor Ort',
            'Übersichtsansicht und Projektnavigation']
        },
        vr: {
          title: 'Vir&shy;tu&shy;el&shy;ler Rund&shy;gang',
          subtitle: 'Projekte von zuhause aus erleben',
          bullets: ['Begehen von Räumen und Plätzen',
            'Virtuelle Realität erleben',
            'Läuft im Büro und zuhause']
        },
        web: {
          title: 'Web In&shy;fo&shy;board',
          subtitle: 'Ihr Projekt als Infowebsite',
          bullets: ['3D Viewer des Digital Twin',
            'Hintergründe übersichtlich präsentiert',
            'Zentrale Anlaufstelle für alle Beteiligten',
            'Inhalte frei verwalten mit CMS',
            'Auch als HTML Plugin']
        },
        creator: {
          title: 'Co-Creator',
          subtitle: 'Gemeinsam Zukunft gestalten',
          bullets: ['Interaktive Werkstatt und Bemusterung',
            'Skizzieren in 3D',
            'Beteiligte gestalten eigene Ideen',
            'Arbeiten Sie gemeinsam in Kleingruppen',
            'Legen Sie den Inter&shy;aktions&shy;rahmen fest']
        },
        umfrage: {
          title: 'In&shy;ter&shy;ak&shy;ti&shy;ve Um&shy;fra&shy;ge',
          subtitle: 'Bürgerbeteiligung in der 3. Dimension',
          bullets: ['Interaktive Befragung am 3D-Modell',
            'Erläutern von Rand&shy;beding&shy;ungen im Raum',
            'Anregungen vor Ort sammeln',
            'Fragebogen für abstrakte Fragen']
        },
        variants: {
          title: 'Va&shy;ri&shy;an&shy;ten&shy;wäh&shy;ler',
          subtitle: 'Treffen Sie die richtige Wahl',
          bullets: ['Schneller Varianten&shy;wechsel',
            'Eindeutige, objektive Darstellung',
            'Wettbewerbs&shy;ergebnisse vergleichbar machen',
            'Frei wählbare Perspektive',
            'Souveräne Ent&shy;schei&shy;dungen']
        },
        pool: {
          title: 'Projektmedien Pool',
          subtitle: 'Ansprechende Bilder für überzeugende Präsentationen',
          bullets: ['Bilder, Videos, 3D-Animationen',
            'Medien für Ihre Präsentation',
            'Beleuchten Sie Details']
        },
        story: {
          title: 'AR Sto&shy;ry&shy;tel&shy;ling',
          subtitle: 'Vermitteln Sie versteckte Details',
          bullets: ['Geschichte, Herausforderungen und Hintergründe erklären',
            'Audiovisuell Ihre Zielgruppe auf Reisen schicken',
            'Vermitteln Sie Inhalte interaktiv und barrierefrei',
            'Passgenau für Ihren Anwend&shy;ungsfall']
        },
        docs: {
          title: 'Do&shy;ku&shy;men&shy;ta&shy;ti&shy;ons&shy;plat&shy;form',
          subtitle: 'Ihre Daten übersichtlich an einem Ort',
          bullets: ['All Ihre Projekte an einem Ort',
            'Statistiken und Ergebnisse aus allen Planungs&shy;schritten zusammen&shy;gefasst',
            'Automatisierte Daten&shy;analyse und Dokument&shy;ation',
            'Ermittlung von Interessens&shy;schwerpunkten',
            'Exportieren Sie einen individuellen Ergebnisreport']
        }
      },
      Startup: {
        title: 'Das Startup',
        subtitle: 'Wer in die Zukunft sehen kann, trifft in der Gegenwart die besseren Entscheidungen!',
        altTitleImg: 'Dronenaufnahme vom cityscaper Team',
        t1: 'Deshalb arbeiten wir für Ihre Zukunft mit dem neusten Tech und den neusten Methoden.',
        h2: 'Gemeinsam schon lange erfolgreich - die cityscaper DNA',
        t2: 'Sebastian und Robin kennen sich noch aus der Schule. Dort erreichten sie gemeinsam die Weltmeisterschaften F1 in Schools in Abu Dhabi. ' +
          'Im Studium gründeten Sie zusammen einen gemeinnützigen Verein. Mit Freund:innen entwickeln, tüfteln, Erfolge feiern: Das ist cityscaper DNA. ' +
          'Coole Technologie, echter Mehrwert für Kund:innen und gleichzeitig Spaß und Freude an den eigenen Aufgaben gehen bei cityscaper Hand in Hand. ' +
          'Egal wann, egal wo und egal wie, wir sind cityscaper. Wir wollen, dass ihr die Städte von morgen gemeinsam gestalten könnt und geben dafür mit unserem Team jeden Tag alles.',
        h3: 'Erst Hackathon, dann Startup. Mehr Klischee geht nicht?',
        t3: 'Die Idee, Augmented Reality in der Stadtgestaltung einzusetzen, stammt tatsächlich von einem Hackathon. ' +
          'Die Anmeldung beim AC2 Gründerwettbewerb machte aus einer Idee ein Projekt. ' +
          'Neben der IHK Aachen wurden wir von der RWTH Aachen umfassend gefördert und konnten Leibniz-Preisträger Prof. Dr. Kobbelt als Mentor für unser Projekt gewinnen. ' +
          'Mit dem EXIST Stipendium ist cityscaper endgültig erfolgreich gestartet und wurde von uns in zahlreichen ' +
          'Wettbewerben rund um das Thema Augmented Reality kontinuierlich weiterentwickelt. ' +
          'Anschließend konnten wir die ersten Kund:innen von cityscaper überzeugen und bauen unsere Erfahrung mit jedem Projekt weiter aus. Heute nutzen wir den CoWorking Space in der Digital Church. ' +
          'Andere Startups, die einen motivieren, kostenloser Kaffee und Tee und die beeindruckende Atmosphäre machen den Space zum Traumarbeitsplatz.',
        milestonesTitle: 'Unsere Milestones:',
        milestones: '<li>AC2 Gründerwettbewerb Nominierte</li>' +
          '<li>Ideation Programm der RWTH Innovation Gewinner HUB Stipendium</li>' +
          '<li>Incubator Batch #4 DigitalHUB 1. Platz</li>' +
          '<li>Virtual Reality Places Festival Hackathon 1. Platz</li>' +
          '<li>5G AR HMS Hackathon 1. Platz</li>' +
          '<li>Venture Villa Batch 09 - Talents</li>'
      },
      DownloadApp: {
        title: 'cityscaper App kostenlos Herunterladen',
        h1: 'Danke für das Herunterladen der',
        h1sub: 'cityscaper App',
        phone1: 'Aus den Stores herunterladen',
        altqr: 'cityscaper App herunterladen',
        appstore1: 'Laden im',
        appstore2: 'App Store',
        playstore1: 'Jetzt bei',
        playstore2: 'GooglePlay',
        h2featurs: 'Entdecken Sie unsere App',
        h3f1: 'Seien Sie vor Ort',
        f1: 'Um das AR-Erlebnis zu starten, gehen Sie zum Projektstandort. Um zu sehen, wo es in der Nähe Projekte für Sie zu entdecken gibt, öffnen Sie die Karte in der Projektübersicht der cityscaper App.',
        h3f2: 'Projekt auswählen',
        f2: 'Wählen Sie am Projektstandort das entsprechende Projekt über die Kachel mit dem gesuchten Titel aus. Sie erhalten nun einen kurzen Überblick über das geplante Projekt. Wenn Sie nun in das AR-Erlebnis eintauchen möchten, drücken Sie "PROJEKT IN AR ANSEHEN".',
        h3f3: 'GPS und Kamera freigeben',
        f3: 'Wenn Sie das erste mal ein AR Erlebnis starten, fragt die App nach dem Zugriff auf Ihr GPS und Ihre Kamera. Weitere Informationen zur Verwendung Ihrer Daten finden Sie in unserer ',
        h3f4: 'Umgebung erkennen',
        f4: 'Zum Anzeigen der virtuellen Inhalte muss die App die Umgebung erkennen. Bewegen Sie die Kamera mit möglichst viel Paralax. Dabei hilft Ihnen der Papierflieger. Wir wünschen viel Spaß!',
        h2herunterladen: 'App kostenlos herunerladen',
        h2error: 'Der Download funktioniert nicht?',
        h3e1: 'Ihr Gerät ist zu alt',
        e1: 'Der Store informiert Sie, wenn Ihr Gerät nicht verfügbar ist. Dies tritt insbesondere bei älteren iOS und Android Geräten auf. Alle unterstützten Geräte finden Sie auf dieser ',
        h3e2: 'Google-Dienste gesperrt',
        e2: 'Haben Sie eine modernes Gerät, die App ist aber nicht verfügbar? Bei einigen Anbietern sind Google-Dienste gesperrt wie z.B. auf Huawei-Geräten, sodass Sie die cityscaper App nicht verwenden können. ',
        h3e3: 'Ihr Anbieter unterstützt kein AR',
        e3: 'Einige Smartphone Anbieter unterstützen AR Anwendungen nicht, unabhängig vom Alter. Mit etwas Aufwand können Sie die Sperre umgehen. Eine Anleitung zum Vorgehen finden Sie ',
        h3e4: 'Ihr Download funktioniert aus anderen Gründen nicht',
        e4: 'Wenn Sie immer noch nicht wissen, warum Ihr Download nicht funktioniert hat, kontaktieren Sie uns gerne.'
      },
      Team: {
        altPortrait: 'Portrait von',
        teamTitle: 'Das Team',
        Robin: {
          role: 'Strategie & Finanzierung',
          catchline: 'Es gibt immer einen Weg nach vorne.'
        },
        Yannic: {
          role: 'Sales & Investment',
          catchline: 'Ich finde Lösungen, wo andere schon aufgegeben haben.'
        },
        Felix: {
          role: 'Technische Entwicklung',
          catchline: 'Unschlagbar bin ich nur bei Teamspielen, ansonsten setzte ich aufs Team.'
        },
        Bernward: {
          role: 'Technische Entwicklung',
          catchline: 'Ich finde jeden Bug. Am Rechner und in der Natur.'
        },
        Rukas: {
          role: 'Freier Mitarbeiter',
          catchline: 'Auch Details müssen bei einem guten Produkt stimmen.'
        },
        Morne: {
          role: 'Freier Mitarbeiter',
          catchline: 'Es brennt? Einfach lachen, denn nur mit guter Laune finden wir eine Lösung.'
        },
        Sebastian: {
          role: 'Technische Entwicklung',
          catchline: 'Manchmal hilf nur eins: Schokolade!'
        },
        Tietje: {
          role: 'Sales',
          catchline: 'Verwaltung kann mehr als verwalten. Kommunale Innovationskraft durfte ich bei cityscaper spüren.'
        }
      },
      Kontakt: {
        title: 'Kontakt',
        altImg: 'Bild Yannic Schwarz',
        text: 'Gerne beraten wir Sie kostenlos und unverbindlich in einem persönlichen Gespräch und zeigen Ihnen, ' +
          'wie Sie Augmented Reality zielführend in Ihrem Projekt nutzen können.',
        l1: 'Kontaktieren Sie uns:'
      },
      Imprint: {
        h1: 'Impressum',
        h2: 'Datenschutzerklärung',
        datenschuEinleitung: 'Hier stellen wir Ihnen die Datenschutzerklärung für die Website und App sowie die Nutzungsbedinungen der App zur Verfügung. Sollten Sie eine ältere Version der Dokumente benötigen oder andere Fragen zum Datenschutz haben, kontaktieren Sie uns bitte per E-Mail unter datenschutz[at]cityscaper.de',
        datenschuWeb: '',
        datenschuApp: ''
      },
      special: {
        head: 'Special: Hier gibt es etwas neues zu Entdecken!',
        dhdl: '<b>Gestalte selber um</b><br>In der App kannst Du anlässlich unserer Austrahlung bei "Die Höhle der Löwen" ausprobieren wie Umgestalten in AR funktioniert!',
        pe: '<b>Deine Immobilie in 3D</b><br>Für Projektentwickler:innen besonder spannend: Finde heraus wie wir Immobilien in 3D in Szene setzen.',
        calendar: 'Direkt unverbindlichen Termin Buchen'
      }
    }
  }
}
